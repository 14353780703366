import axios from 'axios';

export const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_REST_API
});
export const TransactionalAPI = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL
});

export const SetUser = (token) => {
    if (!!token) {
        TransactionalAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    else {
        delete TransactionalAPI.defaults.headers.common['Authorization'];
    }
}

export const UserTokenFactory = _ => {
    return localStorage.getItem("token");
}