import React from 'react';
import PinPointMap from '../../assets/pay-rate-map.png';
import "../../styles/home-page/map-image.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const MapImage = () => {
  return (
    <div className="map-container position-relative">
      <FontAwesomeIcon className="marker" icon={faMapMarkerAlt} />
      <img className="map-image" src={PinPointMap} alt='' />
    </div>
  )
}

export default MapImage;
