import { API } from "./axiosBase";

export const fetchLiveDemoData = async (
  key,
  zip,
  street1,
  street2,
  city,
  state,
  fields
) => {
  console.log("street 2", street2, city);
  var fields_;
    if (fields === "both" || fields === "minwage,rate" ||fields===undefined) {
    fields_ = "";
  } else {
    fields_ = fields;
  }
  const response = await API.get(
    `address?street1=${street1}&street2=${street2}&city=${city}&state=${state}&zip=${zip}&fields=${fields_}&key=${key}`
  );
  return response.data;
};
