import React from "react";
import APISideBar from "../components/api-documentation/APISideBar";
import APIContent from "../components/api-documentation/APIContent";

class ApiDocumentationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.introRef = React.createRef();
    this.httpRequestRef = React.createRef();
    this.responseRef = React.createRef();
    this.schoolRef = React.createRef();
    this.minWageRef = React.createRef();
    this.ratesRef = React.createRef();
    this.paLocalRef = React.createRef();
    this.collectorRef = React.createRef();
    this.ohLocalRef = React.createRef();
  }

  handleOnClick = (item) => {
    if (this.introRef.current) {
      this.introRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  render() {
    return (
      <div className="row" style={{backgroundColor:"white !important", paddingLeft: "calc(350px + 0.5rem)"}}>
        <div className="">
          <APISideBar
            introRef={this.introRef}
            httRequestRef={this.httpRequestRef}
            responseRef={this.responseRef}
            schoolRef={this.schoolRef}
            minWageRef={this.minWageRef}
            ratesRef={this.ratesRef}
            paLocalRef={this.paLocalRef}
            collectorRef={this.collectorRef}
            ohLocalRef={this.ohLocalRef}
          />
        </div>
        <div className="col-12" >
          <APIContent
            introRef={this.introRef}
            httRequestRef={this.httpRequestRef}
            responseRef={this.responseRef}
            schoolRef={this.schoolRef}
            minWageRef={this.minWageRef}
            ratesRef={this.ratesRef}
            paLocalRef={this.paLocalRef}
            collectorRef={this.collectorRef}
            ohLocalRef={this.ohLocalRef}
          />
        </div>
      </div>
    );
  }
}

export default ApiDocumentationContainer;
