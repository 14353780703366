import React, { useEffect, useState } from "react";
import Balance from "../components/dashboard/Balance";
import UsageGraph from "../components/dashboard/UsageGraph";
import APIKeyContainer from "../components/dashboard/APIKeyContainer";
import LastGroupsGraph from "../components/dashboard/LastGroups";
import { TransactionalAPI } from "../api/axiosBase";

const DashboardContainer = ({
    userInfo,
}) => {
    const [apiKey, setApiKey] = useState("");
    useEffect(() => {
        TransactionalAPI
            .get(`getApiKey`)
            .then((response) => {
                setApiKey(response.data.api_key);
            });
        // axios
        //   .post(`${process.env.REACT_APP_SERVER_URL}reportUsage`, {
        //     subscriptionItemID: userInfo.subscription_item_id,
        //     usageQuantity: transactionsCount,
        //   })
        //   .then((response) => {
        //     console.log(response);
        //   });
    }, []);

    return (
        <>
            <Balance />
            <div className="row">
                <UsageGraph />
                <LastGroupsGraph />
            </div>
            <APIKeyContainer apiKey={apiKey} />
        </>
    );
};

export default DashboardContainer;
