import React from "react";
import JSONPretty from "react-json-pretty";
import ResponseObject from "./ResponseObject";
import SchoolDistricts from "./SchoolDistricts";
import MinimumWage from "./MinimumWage";
import Rates from "./Rates";
import PaLocal from "./PaLocal";
import Collector from "./Collector";
import OhLocal from "./OhLocal";

import { exampleRequest as generalExampleRequest } from "../../assets/exampleRequest";
// import { exampleRequest as localWithholdingExampleRequest } from "../../assets/exampleLocalWithholdingRequest";
import { exampleRequest as minWageExampleRequest } from "../../assets/exampleMinWageRequest";
import { ohLocal } from "../../assets/ohLocal";
import { paLocal } from "../../assets/paLocal";
import "../../styles/api-documentation/api-content.scss";

const APIContent = ({
  introRef,
  httRequestRef,
  responseRef,
  schoolRef,
  minWageRef,
  ratesRef,
  paLocalRef,
  collectorRef,
  ohLocalRef,
}) => {
  return (
    <div id="APIContent" className="">
      <div className="row row-c">
        <div className="col-6"></div>
        <div className="col-6 right-container"></div>
      </div>
      <div className="row row-c">
        <div className="col-6">
          <h1 ref={introRef}>Introduction</h1>
          <p className="my-5">
            Payrate’s RESTful API enables you to find local withholding rates
            and minimum wage information for any address in the United States.
          </p>
        </div>
        <div className="col-6 right-container"></div>
      </div>
      <div className="row row-c">
        <div className="col-6">
          <h3 ref={httRequestRef}>HTTP Request</h3>
          <div className="card col-8 offset-2  text-center p-3 my-5">
            <p>https://pay-rate.com/api/v1.0/address</p>
          </div>
        </div>
        <div className="col-6 right-container"></div>
      </div>
      <div className="row row-c">
        <div className="col-6">
          <h3>HTTP Get Parameters</h3>
          
          <table className="table">
            <thead>
              <tr>
                <th>Parameter</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="small-code text-center">street1</div>
                </td>
                <td>address line 1</td>
              </tr>
              <tr>
                <td>
                  <div className="small-code text-center">street2</div>
                </td>
                <td>address line 2</td>
              </tr>
              <tr>
                <td>
                  <div className="small-code text-center">city</div>
                </td>
                <td>city</td>
              </tr>
              <tr>
                <td>
                  <div className="small-code text-center">state</div>
                </td>
                <td>state</td>
              </tr>
              <tr>
                <td>
                  <div className="small-code text-center">zipCode</div>
                </td>
                <td>zip code</td>
              </tr>
              <tr>
                <td>
                  <div className="small-code text-center">key</div>
                </td>
                <td>your api key</td>
              </tr>
              <tr>
                <td>
                  <div className="small-code text-center">fields</div>
                </td>
                <td>
                  [optional parameter]default returns Minimum Wage and Local rates
                  info<br></br>
                  minwage - return only minimum wage information <br></br>
                  rate – return only local rate information
                </td>
              </tr>
            </tbody>
          </table>

          

          <h3>HTTP Status Codes:</h3>
          <p>The API may return the following HTTP status code to indicate success or failure</p>
          
          
          <table className="table">
            <thead>
              <tr>
                <th>Status Code</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="small-code text-center">200	OK</div>
                </td>
                <td>Successful response - even if no results were available</td>
              </tr>
              <tr>
                <td>
                  <div className="small-code text-center">403	Forbidden</div>
                </td>
                <td>
                  API key is missing<br />
                  API key is invalid
                </td>
              </tr>
              <tr>
                <td>
                  <div className="small-code text-center">422	Unprocessable Entity</div>
                </td>
                <td>Not enough information provided to accurately process</td>
              </tr>
              <tr>
                <td>
                  <div className="small-code text-center">500	Internal Server Error</div>
                </td>
                <td>Server side error occurred</td>
              </tr>
            </tbody>
          </table>

        </div>
        <div className="col-6 right-container"></div>
      </div>
      <div className="row row-c">
        <div className="col-6 pt-4">
          <h3 ref={responseRef}>API Response Object</h3>
          <ResponseObject />
        </div>
        <div className="col-6 right-container pl-0">
          <div className="right-title mb-3">Example API Response</div>
          <JSONPretty id="json-pretty" data={generalExampleRequest} />
        </div>
      </div>
      <div className="row row-c">
        <div className="col-6 pt-4">
          <h3 ref={schoolRef}>School Districts</h3>
          <p>
            Depending on the area, the field will return either a unified school
            district or separate secondary and elementary school districts with
            the fields below:
          </p>
          <SchoolDistricts />
        </div>
        <div className="col-6 right-container"></div>
      </div>
      <div className="row row-c">
        <div className="col-6 pt-4">
          <h3 ref={minWageRef}>Minimum Wage</h3>
          <MinimumWage />
        </div>
        <div className="col-6 right-container pl-0">
          <div className="right-title mb-3">Example API Response</div>
          <JSONPretty
            id="json-pretty"
            data={minWageExampleRequest.addressMatches[0].minimumWage}
          />
        </div>
      </div>
      <div className="row row-c">
        <div className="col-6 pt-4">
          <h3 ref={ratesRef}>Rates</h3>
          <p>
            The rates section is a list of local rates. Each element in the list
            will contain the fields below:
          </p>
          <Rates />
          <p className="pb-5">
            Only fields relevant for a particular tax will appear in the
            results.
          </p>
        </div>
        <div className="col-6 right-container pl-0">
          <div className="right-title mb-3">Example API Response</div>
          <JSONPretty
            id="json-pretty"
            data={generalExampleRequest.addressMatches[0].localInfo.rates}
          />
        </div>
      </div>
      <div className="row row-c">
        <div className="col-6">
          <h3 ref={paLocalRef}>PA Local</h3>
          <p>
            Pennsylvania local taxes include state specific information and are
            broken out from other states. The field include are listed below:
          </p>
          <PaLocal />
          <p className="pb-5">
            Only fields relevant for a particular tax will appear in the
            results.
          </p>
        </div>
        <div className="col-6 right-container pl-0">
          <div className="right-title mb-3">Example API Response</div>
          <JSONPretty id="json-pretty" data={paLocal} />
        </div>
      </div>
      <div className="row row-c">
        <div className="col-6">
          <h3 ref={collectorRef}>Collector</h3>
          <p>Information on specific tax collectors. Fields are below:</p>
          <Collector />
          <p className="pb-5">
            Only fields relevant for a particular tax will appear in the
            results.
          </p>
        </div>
        <div className="col-6 right-container pl-0"></div>
      </div>
      <div className="row row-c">
        <div className="col-6">
          <h3 ref={ohLocalRef}>OH Local</h3>
          <p>
            Ohio local taxes include state specific information and are broken
            out from other states. The field include are listed below:
          </p>
          <OhLocal />
        </div>
        <div className="col-6 right-container pl-0">
          <div className="right-title mb-3">Example API Response</div>
          <JSONPretty id="json-pretty" data={ohLocal} />
        </div>
        
      </div>
    </div>
  );
};

export default APIContent;
