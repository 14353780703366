import React from "react";
import "../../styles/home-page/presentation.css";

const Presentation = () => {
  return (
    <div className="presentation-container">
      <h1 className="title">
        One stop shop for minimum wage rates and local withholding taxes.
      </h1>
      <p className="description py-5">
        Using our simple API integration is a snap. Just provide an address and
        PayRate does the rest. Geocoding, minimum wage rates and local tax
        assignment.
      </p>
      {/*
      <Link
        to={{
          pathname: "/livedemo",
          state: { prevPath: "/" },
        }}
      >        
        <Button variant="light" className="live-demo-btn shadow-sm">
          Live Demo
        </Button>
      </Link>
        */}
    </div>
  );
};

export default Presentation;
