import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { TransactionalAPI } from "../../api/axiosBase";
import { toast } from "react-toastify";

import "../../styles/contact-us-page/send-message-form.css"
import { useRef } from "react";

const SendMessageForm = () => {
    const [NameRaw, setNameRaw] = useState("");
    const [EmailRaw, setEmailRaw] = useState("");
    const [BodyRaw, setBodyRaw] = useState("");

    const formRef = useRef(null);
    const emailPattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    const registeredToastWrong = (message) =>
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });


    const handleSubmitClick = (e) => {
        e.preventDefault();

        let Name = NameRaw.trim();
        if (!Name) {
            registeredToastWrong("Please provide a valid name");
            return;
        }


        let Email = EmailRaw.trim();
        if (!Email || !emailPattern.test(Email)) {
            registeredToastWrong("Please provide a valid email");
            return;
        }


        let Body = BodyRaw.trim();
        if (!Body) {
            registeredToastWrong("Please provide a valid body");
            return;
        }


        TransactionalAPI.post("SendContactEmail", { Name, Email, Body })
            .then((res) => {
                if (!!res.data.ok) {
                    toast("Message sent", { type: "success" });
                    formRef.current.reset();
                }
                else {
                    toast(`Message sent ${res.data.error}`, { type: "error" });
                }
            });
    }

    return (
        <div className="send-message-container">
            <Form ref={ formRef }>
                <Form.Group controlId="formName" className="form-text">
                    <Form.Label>Your Name</Form.Label>
                    <Form.Control onChange={(e) => setNameRaw(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="formMail" className="form-text">
                    <Form.Label>Mail</Form.Label>
                    <Form.Control onChange={(e) => setEmailRaw(e.target.value)} />
                </Form.Group>
                <Form.Group controlId="formMessage" className="form-text">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={6} onChange={(e) => setBodyRaw(e.target.value)} />
                </Form.Group>
            </Form>
            <Button
                className="send-btn"
                variant="light"
                type="submit"
                onClick={handleSubmitClick}
            >
                Send Message
            </Button>
        </div>
    );
};

export default SendMessageForm;
