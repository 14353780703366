import React from "react";

const SchoolDistricts = () => (
  <>
          
    <table className="table">
      <thead>
        <tr>
          <th>Parameter</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><div className="small-code text-center">name</div></td>
          <td>name of the school district</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">lea_code</div></td>
          <td>Local Education Agency code</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">grade_low</div></td>
          <td>Lowest supported grade in district</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">grade_high</div></td>
          <td>Highest supported grade in district</td>
        </tr>
      </tbody>
    </table>
  </>
);

export default SchoolDistricts;
