export const ohLocal = {
  ohLocal: {
    municipality: {
      name: "HILLSBORO",
      eeer: "EE",
      rate: 0.015,
      effectiveDate: "20020101",
    },
    schoolDistrict: {
      name: "HILLSBORO CSD",
      eeer: "EE",
      odeNumber: 44123,
      schoolDistrictID: 3604,
      rate: 0.01,
      effectiveDate: "19920101",
    },
    jeddz: {
      name: "Prairie Obetz JEDZ",
      jeddzId: 9055,
      rate: 0.025,
      eeer: "EE",
      effectiveDate: "20091001",
    },
  },
};
