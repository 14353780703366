import React from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import "../../styles/user-registration/user-login.scss";

const Login = ({
  validLoginEmail,
  handleEmail,
  setEmail,
  setPassword,
  login,
  handleIsRegistered,
}) => {
  return (
    <div id="Login">
      <div className="login-container">
        <h4>Sign in to your account</h4>
        <Form>
          <Form.Group className="pt-5" controlId="formEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              className={!validLoginEmail ? "border-not-valid" : ""}
              onChange={(e) => handleEmail(e.target.value)}
              type="email"
              placeholder="Enter email"
            />
            {!validLoginEmail ? (
              <Form.Text className="text-not-valid">
                Enter a valid email
              </Form.Text>
            ) : (
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Password"
            />
          </Form.Group>
          <div className="text-right">
            <Link to="/recover-password" className="btn sign-up-btn mr-3">
              Forgot Password?
            </Link>
            <Link
              className="custom-link"
              to={{
                pathname: "/register",
                state: { prevPath: "/" },
              }}
            >
              <Button className="sign-up-btn" onClick={handleIsRegistered}>
                {" "}
                Sign up free
              </Button>
            </Link>
          </div>
          <div className="text-center pt-5">
            <Button className="sign-in-btn" onClick={login} variant="primary">
              Sign In
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
