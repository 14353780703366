
import React, { useRef } from "react";
import { FileDrop } from "react-file-drop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";

import "../../styles/spreadsheet-processor/file-drop.css";
const Loader = ({ fileHandler }) => {
  const styles = {    
    width: "100%"
  };
  const fileInputRef = useRef(null);

  const onTargetClick = () => {
    fileInputRef.current.click()
  }

  const onFileInputChange = (event) => {
    const { files } = event.target;
    if(!!files) {
      fileHandler(files);
    }
  }

  return (
    <div className="drop-container" style={styles}>
      <input onChange={onFileInputChange} ref={fileInputRef} type="file" className="invisible" />
      <FileDrop onDrop={(files) => fileHandler(files)} onTargetClick={onTargetClick}>
        <div className="icon-container">
          <FontAwesomeIcon icon={faFileUpload} />
        </div>
        <div>
          <h3>Drag your spreadsheet here or click to select it from your file system</h3>
        </div>
      </FileDrop>

    </div>
    
  );
};

export default Loader;
