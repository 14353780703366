import React from "react";

const Collector = () => (
  <>
          
    <table className="table">
      <thead>
        <tr>
          <th>Parameter</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><div className="small-code text-center">name</div></td>
          <td>Tax collector name</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">addressLine1</div></td>
          <td>address line 1</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">addressLine2</div></td>
          <td>address line 2</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">addressLine3</div></td>
          <td>address line 3</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">city</div></td>
          <td>city name</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">county</div></td>
          <td>county name</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">state</div></td>
          <td>state abbreviation</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">zipCode</div></td>
          <td>5 digit zip code</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">zip4</div></td>
          <td>4 digit zip code extension</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">phone</div></td>
          <td>Phone number</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">tollFree</div></td>
          <td>Toll free phone number</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">fax</div></td>
          <td>Fax number</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">email</div></td>
          <td>email address</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">extension</div></td>
          <td>Phone extension</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">url</div></td>
          <td>Collector’s URL</td>
        </tr>
      </tbody>
    </table>
  </>
);

export default Collector;
