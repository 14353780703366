import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../styles/pricing/pricing.scss";

const Pricing = () => {
    return (
        <div id="Pricing">
            <div className="title">
                <h1>Pricing</h1>
            </div>
            <div className="row">
                <div className="col-4 text-center pricing-text">
                    Payrate offers straightfoward pay as you go pricing. Use the service as
                    much or as little as you want and{" "}
                    <p className="font-weight-bold">
                        only get charged what you want to use.
                    </p>{" "}
                    No minimums, no subscription fees.
                </div>
                <div className="col-4 font-weight-bold text-center pricing-text center-text">
                    Each lookup is just $0.05
                </div>
                <div className="col-4 text-center pricing-text">
                    Each data set counts as a lookup. If you select both{" "}
                    <p className="font-weight-bold">minimum wage and local rates,</p> you
                    would pay $0.10 per address
                </div>
            </div>
            <div className="text-center">
                <div className="card-icon">
                    <FontAwesomeIcon icon={faCreditCard} />
                </div>
                <div>
                    <h2>Payment Processing</h2>
                    <p>
                        You will be charged each month on the date your subscription started
                        based on your usage for the previous month.
                    </p>
                </div>
                <div className="btn-container">
                    <Link
                        className="custom-link"
                        to={{
                            pathname: "/register",
                            state: { prevPath: "/", loadScreen: "register" },
                        }}
                    >
                        <Button className="sign-up-btn">
                            Sign Up FREE
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Pricing;
