import React, { useEffect, useState } from "react";
import { fetchLiveDemoData } from "../api/liveDemoApi";
import DemoForm from "../components/live-demo/DemoForm";
import DemoMap from "../components/live-demo/DemoMap";
// import DemoResults from "../components/live-demo/DemoResults";
import JsonViewer from 'react-json-pretty';
import Loader from "react-loader-spinner";
import "../styles/live-demo/live-demo.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
// import { Tab, Tabs } from "react-bootstrap";

const LiveDemoContainer = () => {
  const [liveDemoData, setLiveDemoData] = useState([
    { coordinates: { lat: "40.4127355", lng: "-3.696428" } },
  ]);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [viewDemoKey, setViewDemoKey] = useState("raw");
  
  useEffect(() => {
    if (zip !== "" && address1 !== "") {
      setIsLoading(true);
      fetchLiveDemoData("prate_0Bk3MCT8z9hCaZsWt4bU2CN6A4yv2OwJRl48Ua", zip, address1, address2, city, state).then(
        (res) => {
          setLiveDemoData(res);
          setIsLoading(false);
        }
      );
    }
  }, [zip, address1, address2, city, state]);

  return (
    <div id="LiveDemo">
      <div className="live-demo-container text-center">
        <h2 className="demo-title">Live Demo</h2>
      </div>
      <div className="row">
        <div className="col-6">
          <DemoForm
            setAddress1={setAddress1}
            setAddress2={setAddress2}
            setCity={setCity}
            setState={setState}
            setZip={setZip}
          />
          <div>
            <div>
              {liveDemoData.addressMatches !== undefined ? (
                <div className="mx-5">     
                  <div className="top-background shadow-sm">
                    <FontAwesomeIcon
                      className="pl-1"
                      style={{ color: "#f2555c" }}
                      icon={faCircle}
                    />
                    <FontAwesomeIcon
                      className="pl-1"
                      style={{ color: "#efc778" }}
                      icon={faCircle}
                    />
                    <FontAwesomeIcon
                      className="pl-1"
                      style={{ color: "#b5e08d" }}
                      icon={faCircle}
                    />
                  </div>
                  <div className="example-json-container shadow-sm">
                    <JsonViewer data={liveDemoData} className='DemoResults' />
                          
                  </div>
                </div>
                /*<Tabs activeKey={viewDemoKey} onSelect={k => setViewDemoKey(k)}>
                  <Tab eventKey="processed" title="Processed Results">
                    <DemoResults
                      address={liveDemoData.addressMatches[0].address.address}
                      city={liveDemoData.addressMatches[0].address.city}
                      county={liveDemoData.addressMatches[0].address.county}
                      state={liveDemoData.addressMatches[0].address.state}
                      minimumWageFederal={
                        liveDemoData.addressMatches[0].minimumWage.federal.wage1
                      }
                      minimumWageState={
                        liveDemoData.addressMatches[0].minimumWage.state.wage1
                      }
                      schoolDistricts={
                        liveDemoData.addressMatches[0].schoolDistricts.unified.name
                      }
                      schoolDistrictsLeaCode={
                        liveDemoData.addressMatches[0].schoolDistricts.unified
                          .lea_code
                      }
                      schoolDistrictsGradeLow={
                        liveDemoData.addressMatches[0].schoolDistricts.unified
                          .grade_low
                      }
                      schoolDistrictsGradeHigh={
                        liveDemoData.addressMatches[0].schoolDistricts.unified.grade_high
                      }
                    />
                  </Tab>
                  <Tab eventKey="raw" title="Raw Results">
                    <JsonViewer data={liveDemoData} className='DemoResults' />
                  </Tab>
                </Tabs>  */
              ) : !isLoading ? (
                <h3>Fill the form to run the demo!</h3>
              ) : (
                <div className="text-center card">
                  <Loader
                    type="ThreeDots"
                    color="#39517a"
                    height={200}
                    width={100}
                    timeout={30000} //3 secs
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-6">
          <DemoMap
            lat={
              liveDemoData.addressMatches !== undefined
                ? liveDemoData.addressMatches[0].coordinates.lat
                : "40.209092"
            }
            lng={
              liveDemoData.addressMatches !== undefined
                ? liveDemoData.addressMatches[0].coordinates.lng
                : "-75.605421"
            }
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default LiveDemoContainer;
