import React from "react";
import { Menu, Sidebar } from "semantic-ui-react";
import PayRateLogoW from "../../assets/pay-rate-logo-w.png";
import { Link } from "react-router-dom";

import "../../styles/api-documentation/api-sidebar.scss";

const SideBar = ({
  introRef,
  httRequestRef,
  responseRef,
  schoolRef,
  minWageRef,
  ratesRef,
  paLocalRef,
  collectorRef,
  ohLocalRef,
}) => {
  return (
    <div id="APISideBar">
      <Sidebar
        as={Menu}
        animation="push"
        direction="left"
        icon="labeled"
        inverted
        vertical
        visible={true}
        width="wide"
        className="side-bar-body"
      >
        <Link
          to={{
            pathname: "/",
            state: { prevPath: "/" },
          }}
        >
          <img className="header-img" src={PayRateLogoW} alt="" />
        </Link>
        <Menu.Item
          onClick={() =>
            introRef.current
              ? introRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              : console.log("not rendered")
          }
          className="menu-item"
          as="span"
        >
          Introduction
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            httRequestRef.current
              ? httRequestRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              : console.log("not rendered")
          }
          className="menu-item-secu"
          as="span"
        >
          HTTP Request
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            responseRef.current
              ? responseRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              : console.log("not rendered")
          }
          className="menu-item-secu"
          as="span"
        >
          API Response objects
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            schoolRef.current
              ? schoolRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              : console.log("not rendered")
          }
          className="menu-item-secu"
          as="span"
        >
          School Districts
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            minWageRef.current
              ? minWageRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              : console.log("not rendered")
          }
          className="menu-item-secu"
          as="span"
        >
          Minimum Wage
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            ratesRef.current
              ? ratesRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              : console.log("not rendered")
          }
          className="menu-item-secu"
          as="span"
        >
          Rates
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            paLocalRef.current
              ? paLocalRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              : console.log("not rendered")
          }
          className="menu-item-secu"
          as="span"
        >
          PA Local
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            collectorRef.current
              ? collectorRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              : console.log("not rendered")
          }
          className="menu-item-secu"
          as="span"
        >
          Collector
        </Menu.Item>
        <Menu.Item
          onClick={() =>
            ohLocalRef.current
              ? ohLocalRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              : console.log("not rendered")
          }
          className="menu-item-secu"
          as="span"
        >
          OH Local
        </Menu.Item>
      </Sidebar>
    </div>
  );
};

export default SideBar;
