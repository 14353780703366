import React, { useState } from "react";
import { ExcelRenderer } from "react-excel-renderer";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Loader from "../components/spreadsheet_processor/Loader";
import Preview from "../components/spreadsheet_processor/Preview";
import Processor from "../components/spreadsheet_processor/Proccessor";

import "../styles/spreadsheet-processor/container.scss";
import BulkUploadProcessReportComponent from "../components/bulk-upload/BulkUploadProcess";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router";
import { TransactionalAPI } from "../api/axiosBase";

toast.configure();

const BatchProcessingContainer = ({ userInfo }) => {
    const [cols, setcols] = useState();
    const [rows, setRows] = useState();
    const [filename, setFilename] = useState("");
    const [file, setFile] = useState();
    const [isWageChecked, setIsWageChecked] = useState(false);
    const [isRatesChecked, setIsRatesChecked] = useState(false);
    const [transactionGroupId, setTransactionGroupId] = useState("");
    const [processedData, setProcessedData] = useState([]);



    const fileHandler = (files, e) => {
        ExcelRenderer(files[0], (err, resp) => {
            if (err) {
                console.log(err);
            } else {
                setFile(files[0]);
                setFilename(files[0].name);
                setRows(resp.rows);
                setcols(resp.cols);
            }
        });
    };

    const handleFields = () => {
        if (isWageChecked === true && isRatesChecked === false) {
            console.log("minwage");
            return "minwage";
        } else if (isWageChecked === false && isRatesChecked === true) {
            console.log("rate");
            return "rate";
        }
        return "minwage,rate";
    };

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const getData = () => {
        if (isWageChecked === false && isRatesChecked === false) {
            toast("Please select to query Minimum Wage, Local Rates or both", { type: "error" });
            return;
        }

        const time_stamp = formatDate(Date());
        console.log("time stamp", time_stamp);

        if (!!rows) {
            let fields = handleFields();
            var dataPayload = rows.slice(1).map(r => ({
                time_stamp: time_stamp,
                street1: r[0],
                street2: r[1] ? r[1].replace("#", "") : "",
                city: r[2],
                state: r[3],
                zip: r[4],
                fields
            }));

            let formData = new FormData();
            formData.append("file", file);
            formData.append("filename", filename);

            formData.append("data", JSON.stringify(dataPayload));

            TransactionalAPI
                .post(`bulkRegister`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                .then((res) => {
                    setTransactionGroupId(res.data.transactionGroupId);

                    // handleBalance(handleFields());
                })
        }
    };

    const fnFinishedLoading = (data) => {
        setProcessedData(data);
    }

    console.log("processed data=>", processedData);

    if ((!userInfo || !userInfo.subscription_item_id)) {
        return <Redirect to='/payment' />
    }
    else {
        return (
            <div id="BatchProccesorContainer">
                <h1>Batch Processing</h1>
                { !!transactionGroupId && rows.length - 1 !== processedData.length ? (
                    <BulkUploadProcessReportComponent transactionGroupId={transactionGroupId} fnFinishedLoading={fnFinishedLoading} />
                ) : (
                    <>
                        <div className="row file-loader">
                            <Loader fileHandler={fileHandler} />
                            <div className="col-12">
                            <hr />
                            <h3 className="mt-3">Example file contents</h3>

                            <p>Build a spreadsheet with the following schema, replace the values to be looked up and upload it in the control above</p>
                                <table className="table m-4">
                                    <thead>
                                        <tr>
                                            <th>Address Line 1</th>
                                            <th>Address Line 2</th>
                                            <th>City</th>
                                            <th>State</th>
                                            <th>Zip</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>50 N Ripley St</td>
                                            <td></td>
                                            <td>Montgomery</td>
                                            <td>AL</td>
                                            <td>36104</td>
                                        </tr>
                                        <tr>
                                            <td>2645 Skyland Blvd E</td>
                                            <td></td>
                                            <td>Tuscaloosa</td>
                                            <td>AL</td>
                                            <td>35405</td>
                                        </tr>
                                        <tr>
                                            <td>100 S Clinton</td>
                                            <td># C</td>
                                            <td>Athens</td>
                                            <td>AL</td>
                                            <td>35611</td>
                                        </tr>
                                        <tr>
                                            <td>18 Alabama Ave E</td>
                                            <td></td>
                                            <td>La Fayette</td>
                                            <td>AL</td>
                                            <td>36862</td>
                                        </tr>
                                        <tr>
                                            <td>1801 3rd Ave N</td>
                                            <td></td>
                                            <td>Bessemer</td>
                                            <td>AL</td>
                                            <td>35020</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <Preview cols={cols} rows={rows} />
                        </div>
                        {!!rows ? (
                            <>
                                <div className="appends-title">
                                    <h2>
                                        Appends &nbsp;&nbsp;&nbsp;
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </h2>
                                </div>
                                <div className="row">
                                    <div className="col-2 check-container">
                                        <Form.Group controlId="wageCheckBox">
                                            <Form.Check
                                                defaultChecked={isWageChecked}
                                                onChange={() => setIsWageChecked(!isWageChecked)}
                                                type="checkbox"
                                                label="Minimum Wage"
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-2 check-container">
                                        <Form.Group controlId="ratesCheckBox">
                                            <Form.Check
                                                type="checkbox"
                                                defaultChecked={isRatesChecked}
                                                onChange={() => setIsRatesChecked(!isRatesChecked)}
                                                label="Local Rates"
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        <div className="text-center">
                            {!!rows ? (
                                <Button className="process-btn" onClick={getData}>
                                    {" "}
                                    Process{" "}
                                </Button>
                            ) : (
                                <></>
                            )}
                            {!!rows ? (
                                rows.length - 1 === processedData.length ? (
                                    <Processor
                                        id="ProcessorBtn"
                                        data={processedData}
                                        isWageChecked={isWageChecked}
                                        isRatesChecked={isRatesChecked}
                                    />
                                ) : (
                                    <></>
                                )
                            ) : (
                                <></>
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    }
};

export default BatchProcessingContainer;

