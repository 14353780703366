import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { TransactionalAPI } from "../../api/axiosBase";

import "../../styles/dashboard/dashboard.scss";

const LastGroupsGraph = () => {
    const PAGE_SIZE = 20;

    const [lastGroups, setLastGroups] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        TransactionalAPI
            .get(`lastGroups`)
            .then((response) => {
                console.log("last groups data", response.data.groups)
                setLastGroups(response.data.groups);


                setPageNumber(0);
                setPageCount(Math.ceil(response.data.groups.length / PAGE_SIZE));
            });
    }, [])


    return (
        <div id="LastGroupsReport" className="col-9 pt-5">
            <h2>Last Bulk Search Report</h2>
            <div className="card pt-2">
                <div className="row">
                    <div className="col-4 font-weight-bold">Date</div>
                    <div className="col-4 font-weight-bold">Filename</div>
                    <div className="col-4 font-weight-bold">Lookups</div>
                </div>
                {!!lastGroups
                    ? lastGroups.slice(pageNumber * PAGE_SIZE, pageNumber * PAGE_SIZE + PAGE_SIZE).map(({ transaction_group_id, transaction_group_filename, time_stamp, count }) => (
                        <div className="row report-item" key={transaction_group_id}>
                            <div className="col-4">
                                <span className="date">
                                    <Moment format="DD MMM YYYY">{time_stamp}</Moment>
                                    {/* {time_stamp} */}
                                </span>
                            </div>
                            <div className="col-4">{transaction_group_filename}</div>
                            <div className="col-2">{count}</div>
                            <div className="col-2"><Link to={`/groupDownload/${transaction_group_id}`}>View</Link></div>
                        </div>
                    ))
                    : ""}

                <div className="btn-group">{[...Array(pageCount).keys()].map(i => (<button key={i} className="btn btn-xs" onClick={() => setPageNumber(i)}>{i + 1}</button>))}</div>
            </div>
        </div>
    );
}
export default LastGroupsGraph;