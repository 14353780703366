import React, { useState } from "react";
import Login from "../components/login/Login";
import Register from "../components/login/Register";
import { toast, ToastContainer } from "react-toastify";
import { suid } from "rand-token";
import "react-toastify/dist/ReactToastify.css";
import { SetUser, TransactionalAPI } from "../api/axiosBase";

const UserLoginContainer = ({ setAuthStatus, loadScreen }) => {
    const [emailReg, setEmailReg] = useState("");
    const [passwordReg, setPasswordReg] = useState("");
    const [nameReg, setNameReg] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [viewLoginScreen, setViewLoginScreen] = useState(!loadScreen && loadScreen != "register");
    const [validLoginEmail, setValidLoginEmail] = useState(false);
    const [validRegEmail, setValidRegEmail] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleEmail = (value) => {
        var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (pattern.test(value)) {
            setValidLoginEmail(true);
            setEmail(value);
        } else {
            setValidLoginEmail(false);
            setEmail("");
            console.log("not valid");
        }
    };

    const handleRegEmail = (value) => {
        var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (pattern.test(value)) {
            setValidRegEmail(true);
            setEmailReg(value);
        } else {
            setValidRegEmail(false);
            setEmailReg("");
            console.log("not valid");
        }
    };

    const registeredToast = () =>
        toast.success("Success! Registered correctly", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    const registeredToastWrong = (message) =>
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    const handleName = (fullName) => {
        if (fullName !== "") {
            var res = fullName.split(" ");
            return res;
        }
        return fullName;
    };
    const handleIsRegistered = () => {
        setViewLoginScreen(!viewLoginScreen);
    };
    const register = () => {
        const firstName = handleName(nameReg)[0];
        const lastName = handleName(nameReg)[1];
        const apiKeyGen = "prate_" + suid(30);
        console.log(checked);
        console.log(passwordReg);
        if (!validRegEmail) {
            console.log("not a valid email for registration");
            registeredToastWrong("Provide a valid E-mail");
        } else if (firstName === undefined) {
            registeredToastWrong("Provide your name");
        } else if (passwordReg === undefined || passwordReg === "") {
            registeredToastWrong("Provide a valid password");
        } else if (passwordReg !== confirmPassword) {
            registeredToastWrong("Password doesn't match");
        } else if (checked === false) {
            registeredToastWrong("Please check Terms of Use");
        }
        else {
            TransactionalAPI
                .post(`register`, {
                    email: emailReg,
                    password: passwordReg,
                    first_name: firstName,
                    last_name: lastName,
                    api_key: apiKeyGen,
                })
                .then((response) => {
                    if (response.data.registered === true) {
                        registeredToast();
                        console.log("email", response.data);
                        //setIsRegistered(true);
                        localStorage.setItem("token", response.data.token);
                        SetUser(response.data.token);
                        setAuthStatus(true);
                    } else {
                        registeredToastWrong("User already exists");
                    }
                })
                .catch((err) => {
                    registeredToastWrong(err);
                });
            console.log("billing email", emailReg);
        }
    };

    const login = () => {
        if (!validLoginEmail) {
            console.log("not a valid email");
        } else {
            TransactionalAPI
                .post(`login`, {
                    email: email,
                    password: password,
                })
                .then((response) => {
                    if (response.data.auth === false) {
                        registeredToastWrong("Wrong E-mail/Password combination");
                        setAuthStatus(false);
                    } else {
                        console.log("login", response.data.token);
                        localStorage.setItem("token", response.data.token);
                        SetUser(response.data.token);
                        setAuthStatus(true);
                    }
                });
        }
    };

    return (
        <div>
            {viewLoginScreen ? (
                <Login
                    validLoginEmail={validLoginEmail}
                    handleEmail={handleEmail}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    login={login}
                    handleIsRegistered={handleIsRegistered}
                />
            ) : (
                <>
                    <ToastContainer
                        position="top-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Register
                        handleRegEmail={handleRegEmail}
                        validRegEmail={validRegEmail}
                        setEmailReg={setEmailReg}
                        setPasswordReg={setPasswordReg}
                        register={register}
                        setNameReg={setNameReg}
                        setConfirmPassword={setConfirmPassword}
                        handleIsRegistered={handleIsRegistered}
                        setChecked={setChecked}
                        checked={checked}
                    />
                </>
            )}
        </div>
    );
};

export default UserLoginContainer;
