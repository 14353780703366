import React from "react";

const MinimumWage = () => (
  <>          
    <table className="table">
      <thead>
        <tr>
          <th>Parameter</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><div className="small-code text-center">state</div></td>
          <td>State abbreviation or “Federal”</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">locality</div></td>
          <td>Locality imposing minimum wage</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">wage1</div></td>
          <td>minimum wage per hour</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">tippedWage1</div></td>
          <td>minimum wage for tipped employees</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">wage1WithBenefits</div></td>
          <td>minimum wage for employees who are offered benefits</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">employeesLessThanOrEqualTo</div></td>
          <td>Number of employees for which wage 1 is applicable</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">revenueLessThan</div></td>
          <td>Amount of revenue for which wage 1 is applicable</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">wage2</div></td>
          <td>wage2 is applicable when employee count or revenue is greater than
          values in above fields</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">tippedWage2</div></td>
          <td>tippedWage2 is applicable for tipped employees when employee count or
          revenue is greater than values in above fields</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">wage2WithBenefits</div></td>
          <td>minimum wage for employees who are offered benefits when employee
          count or revenue is greater than values in above fields</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">effectiveDate</div></td>
          <td>effective date of minimum wage ordinance Only the fields relevant to a
          particular minimum wage will appear in result</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">note</div></td>
          <td>Minimum Wage Note</td>
        </tr>
      </tbody>
    </table>
  </>
);

export default MinimumWage;
