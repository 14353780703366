import React from "react";

const PaLocal = () => (
  <>
    <table className="table">
      <thead>
        <tr>
          <th>TaxCollectionDistrict</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><div className="small-code text-center">taxCollectionDistrictName</div></td>
          <td>Name of the tax collection district</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">county</div></td>
          <td>county name</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">municipality</div></td>
          <td>municipality name</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">municipalityID</div></td>
          <td>unique municipality ID code</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">schoolDistrict</div></td>
          <td>School district name</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">schoolDistrictID</div></td>
          <td>unique school district ID code</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">municipalResidentEITRate</div></td>
          <td>Municipal resident earned income tax rate</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">municipalResidentEITRateEffDate</div></td>
          <td>Municipal resident earned income tax rate effective date</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">municipalNonResidentEITRate</div></td>
          <td>Municipal nonresident earned income tax rate</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">municipalNonResidentEITRateEffDate</div></td>
          <td>Municipal nonresident earned income tax rate effective date</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">municipalLST</div></td>
          <td>Municipal local services tax amount(dollars)</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">municipalLSTEffDate</div></td>
          <td>Municipal local services tax effective date</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">schoolDistrictEITRate</div></td>
          <td>School district earned income tax rate</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">schoolDistrictEITRateEffDate</div></td>
          <td>School district earned income tax effective date</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">schoolDistrictPITRate</div></td>
          <td>School District personal income tax rate</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">schoolDistrictLST</div></td>
          <td>School District local services tax rate</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">schoolDistrictLSTEffDate</div></td>
          <td>School District local services tax effective date</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">municipalEITLIE</div></td>
          <td>Municipal earned income tax low income</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">schooDistrictEITLIE</div></td>
          <td>School district earned income tax low income</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">eitCollector</div></td>
          <td>Earned income tax collector</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">municipalLSTCollector</div></td>
          <td>Municipal local services tax collector</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">schoolDistrictLSTCollector</div></td>
          <td>School District local services tax collector</td>
        </tr>
      </tbody>
    </table>
  </>
);

export default PaLocal;
