import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import "../../styles/contact-us-page/contact-info.scss";

const ContactInfo = () => {
    return (
        <div id="ContactInfo">
            <div className="contact-info">
                <h1>Get in touch</h1>
                <h3 className="">We are happy to hear from you.</h3>
                <p className="contact-font">
                    Fill up the form and our team will get back to you within 24 hours
                </p>
                <div className="row email-font pb-5 pl-3 align-middle">
                    <FontAwesomeIcon className="contact-icon mr-3 mt-2" icon={faEnvelope} />
                    support@pay-rate.com
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;
