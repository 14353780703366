import React from "react";

import "../../styles/home-page/services.scss";

const Services = () => {
  return (
    <div id="Services" className="row mx-auto">
      <div className="col-4 p-5">
        <div className="card shadow-sm p-4 ">
          <div>
            <p className="item-text align-middle">REST API</p>
          </div>
          <div>
            <p className="item-text align-middle">Integrate in minutes</p>
          </div>
          <div>
            <p className="item-text align-middle">Real-time Geocoding</p>
          </div>
          <div>
            <p className="item-text align-middle">Batch Procesing</p>
          </div>
        </div>
      </div>
      <div className="col-4 p-5">
        <div className="card shadow-sm p-4">
          <div>
            <p className="item-text align-middle">Address corrections</p>
          </div>
          <div>
            <p className="item-text align-middle">Local tax assignment</p>
          </div>
          <div>
            <p className="item-text align-middle">Minimum wage rates</p>
          </div>
        </div>
      </div>
      <div className="col-4 p-5">
        <div className="card shadow-sm p-5 bp-text">
          <h3>Batch Procesing</h3>
          <p className="batch-description">
            Whether you have hundreds or thousands of adresses. Simply upload a
            spreadsheet and PayRate will provide exactly what you need.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
