import React, { useState } from "react";
import SendMessageForm from "../components/contact-us/SendMessageForm";
import ContactInfo from "../components/contact-us/ContactInfo";

import "../styles/contact-us-page/contact-us.css";

const ContactUsContainer = () => {  
  return (
    <div className="row mx-auto contact-background pb-5">
      <div className="col-6 contact-info-container">
        <ContactInfo />
      </div>
      <div className="col-6">
        <SendMessageForm />
      </div>
    </div>
  );
};

export default ContactUsContainer;
