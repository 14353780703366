import React from "react";

const OhLocal = () => (
  <>
    <table className="table">
      <thead>
        <tr>
          <th>Municipality</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><div className="small-code text-center">name</div></td>
          <td>Municipality name</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">rate</div></td>
          <td>Municipality income tax rate</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">effectiveDate</div></td>
          <td>Effective date</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">effEndDate</div></td>
          <td>Effective end date</td>
        </tr>
      </tbody>
    </table>
          
    <table className="table">
      <thead>
        <tr>
          <th>SchoolDistrict</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><div className="small-code text-center">name</div></td>
          <td>School district name</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">odeNumber</div></td>
          <td>Ohio Department of Education IRN number</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">schoolDistrictID</div></td>
          <td>Taxation school district number</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">rate</div></td>
          <td>School district income tax rate</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">effectiveDate</div></td>
          <td>Effective date</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">effEndDate</div></td>
          <td>Effective end date</td>
        </tr>
      </tbody>
    </table>
          
    <table className="table">
      <thead>
        <tr>
          <th>JEDDZ</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><div className="small-code text-center">name</div></td>
          <td>JEDD/JEDZ name</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">jeddzId</div></td>
          <td>JEDD/JEDZ Id</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">rate</div></td>
          <td>JEDD/JEDZ income tax rate</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">eeer</div></td>
          <td>EE specifies employee tax, ER specifies employer tax</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">effectiveDate</div></td>
          <td>Effective date</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">effEndDate</div></td>
          <td>Effective end date</td>
        </tr>
      </tbody>
    </table>
  </>
);

export default OhLocal;
