import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import PayRateLogo from "../assets/pay-rate-logo.png";
import { Link } from "react-router-dom";
import "../styles/nav-bar.scss";

const NavBar = () => {
  return (
    <>
      <Navbar id="NavBar" className="nav-bar-custom" variant="light">
        <Navbar.Brand href="/">
          <img className="logo" src={PayRateLogo} alt="" />
        </Navbar.Brand>
        <Nav className="mr-auto">
          <Link
            to={{
              pathname: "/",
              state: { prevPath: "/" },
            }}
          >
            <Button variant="light" className="link-button">
              Home
            </Button>
          </Link>
          <Link
            to={{
              pathname: "/pricing",
              state: { prevPath: "/" },
            }}
          >
            <Button variant="light" className="link-button">
              Pricing
            </Button>
          </Link>
          <Link
            to={{
              pathname: "/contact-us",
              state: { prevPath: "/" },
            }}
          >
            <Button variant="light" className="link-button">
              Contact Us
            </Button>
          </Link>
          <Link
            to={{
              pathname: "/api",
              state: { prevPath: "/" },
            }}
          >
            <Button variant="light" className="link-button">
              API
            </Button>
          </Link>
        </Nav>
        <Link
          to={{
            pathname: "/register",
            state: { prevPath: "/" },
          }}
        >
          <Button variant="light" className="sign-button">
            Log in
          </Button>
        </Link>
      </Navbar>
    </>
  );
};

export default NavBar;
