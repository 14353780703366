import React, { useState, useEffect } from "react";
//import { ExcelRenderer } from "react-excel-renderer";
//import { Button, Form } from "react-bootstrap";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
//import Loader from "../components/spreadsheet_processor/Loader";
//import Preview from "../components/spreadsheet_processor/Preview";
import Processor from "../components/spreadsheet_processor/Proccessor";

import "../styles/spreadsheet-processor/container.scss";
//import { BulkUploadProcessReportComponent } from "../components/bulk-upload/BulkUploadProcess";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router";
import { TransactionalAPI } from "../api/axiosBase";
import Moment from "react-moment";

toast.configure();

const DownloadGroupContainer = ({ userInfo }) => {
    // const [cols, setcols] = useState();
    // const [rows, setRows] = useState();
    const [isWageChecked, setIsWageChecked] = useState(false);
    const [isRatesChecked, setIsRatesChecked] = useState(false);
    // const [data, setData] = useState([]);
    const [processedData, setProcessedData] = useState([]);
    const [filename, setFilename] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [originalFilename, setOriginalFilename] = useState(null);
    const [timestamp, setTimestamp] = useState(null);
    const { transaction_group_id } = useParams();

    useEffect(() => {


        TransactionalAPI
            .get(`groupData?group=${transaction_group_id}`)
            .then((response) => {
                console.log("groupData result", { response });
                // setData(response.data.transactions.map(d => JSON.parse(d.transaction_payload)));
                setProcessedData(response.data.transactions.map(d => JSON.parse(d.transaction_result)));
                setTransactions(response.data.transactions);
                setFilename(response.data.filename);
                setOriginalFilename(response.data.originalfilename);
                setTimestamp(response.data.timestamp);

                if (response.data.transactions[0].fields === "minwage") {
                    setIsWageChecked(true);
                    setIsRatesChecked(false);
                } else if (response.data.transactions[0].fields === "rate") {
                    setIsWageChecked(false);
                    setIsRatesChecked(true);
                } else { // "both"
                    setIsWageChecked(true);
                    setIsRatesChecked(true);
                }
            });
    }, [transaction_group_id])

    // const fileHandler = (files, e) => {
    //   ExcelRenderer(files[0], (err, resp) => {
    //     if (err) {
    //       console.log(err);
    //     } else {
    //       setRows(resp.rows);
    //       setcols(resp.cols);
    //     }
    //   });
    // };

    const downloadOriginalFile = () => {
        TransactionalAPI
            .get(`getGroupOriginalFile?group=${transaction_group_id}`, { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', originalFilename); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
    }

    return (
        <div id="BatchProccesorContainer">
            {/* <div className="row">
              <Preview cols={cols} rows={rows} />
            </div> */}
            <h1>Processed File Report</h1>
            <div className="row m-4">
                <div className="col-md-6 metadataBox">
                    <h1>Filename</h1>
                    <span>{originalFilename}</span>
                </div>
                <div className="col-md-6 metadataBox">
                    <h1>Timestamp</h1>
                    <span>
                        <Moment format="DD MMM YYYY HH:mm:ss">{timestamp}</Moment>
                    </span>
                </div>
                {/*<div className="col-md-4 metadataBox">*/}
                {/*    <h1>Processing Time</h1>*/}
                {/*    <span>{`${transactions.reduce((prev, curr) => prev + curr.seconds_to_complete, 0).toFixed(2)} secs.`}</span>*/}
                {/*</div>*/}
                <div className="col-md-6 metadataBox">
                    <h1>Transactions</h1>
                    <span>{`${transactions.reduce((prev, curr) => prev + ((curr.fields == "both" || curr.fields == "minwage,rate" || !curr.fields) ? 2 : 1), 0)}: ${transactions.length > 0 ? transactions[0].fields : "" }`}</span>
                </div>
                <div className="col-md-6 metadataBox">
                    <h1>Rows</h1>
                    <span>{transactions.length}</span>
                </div>
            </div>

            
            <div className="row">
                <div className="col-md-6 text-center text-md-right">
                    <span>
                        <button onClick={downloadOriginalFile}>Download Original File</button>
                    </span>
                </div>
                <div className="col-md-6 text-center text-md-left">
                    {!!processedData &&
                        processedData.length > 0 &&
                        (!!isWageChecked || !!isRatesChecked)
                        && (
                            <Processor
                                id="ProcessorBtn"
                                data={processedData}
                                filename={filename}
                                isWageChecked={isWageChecked}
                                isRatesChecked={isRatesChecked}
                            />
                        )}
                </div>
            </div>
            <div className="text-center">
            </div>
        </div>
    );
};

export default DownloadGroupContainer;

