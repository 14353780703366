import React from "react";

const Rates = () => (
  <>
    <table className="table">
      <thead>
        <tr>
          <th>rate</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><div className="small-code text-center">state</div></td>
          <td>state abbreviation</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">county</div></td>
          <td>county name</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">jurisdiction</div></td>
          <td>jurisdiction name</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">zipCode</div></td>
          <td>zip code. Only relevant for Oregon Trimet Transit and Lane Transit
          District taxes</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">taxname</div></td>
          <td>name of the tax</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">rateLevel</div></td>
          <td>jurisdiction level for which tax is applicable 2 = county level, 3 =
          city level</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">eeer</div></td>
          <td>EE specifies employee tax, ER specifies employer tax</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">residentRate</div></td>
          <td>tax rate for residents of jurisdiction</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">nonResidentRate</div></td>
          <td>tax rate for nonresidents of jurisdiction</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">defaultRate</div></td>
          <td>default tax rate for jurisdiction</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">maxtax</div></td>
          <td>Maximum tax amount to be collected</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">amount</div></td>
          <td>Flat tax amount in dollars</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">mentalHealthRate</div></td>
          <td>Mental health tax rate</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">schoolDistrictRate</div></td>
          <td>School district tax rate</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">taxExemptLimit</div></td>
          <td>Individual exemption limit</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">note</div></td>
          <td>Notes associated with tax</td>
        </tr>
      </tbody>
    </table>
  </>
);

export default Rates;
