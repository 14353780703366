import React, { useState } from 'react';
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';

import "../styles/user-registration/user-login.scss";
import { TransactionalAPI } from '../api/axiosBase';

const RecoverPasswordPage = () => {
    const [validRegEmail, setValidRegEmail] = useState(false);
    const [emailReg, setEmailReg] = useState("");
        
    const registeredToast = () =>
        toast.success("The instructions to change password are sent to your inbox if the email typed was registered", {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        
    const registeredToastWrong = (message) =>
    toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    
  const handleRegEmail = (value) => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (pattern.test(value)) {
      setValidRegEmail(true);
      setEmailReg(value);
    } else {
      setValidRegEmail(false);
      setEmailReg("");
      console.log("not valid");
    }
  };

    const sendRecovery = () => {
        if (!validRegEmail) {
            console.log("not a valid email for registration");
            registeredToastWrong("Provide a valid E-mail");
        }
        else {
            TransactionalAPI
                .post(`recover-password-request`, {
                    email: emailReg
                })
                .then((response) => {
                    if (response.data.ok === true) {
                        registeredToast();
                    }
                });
            console.log("billing email", emailReg);
        }
    }

    return (
        <div id="Login">
            <div className="login-container">
                <h4>Recover password</h4>
                <Form>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                    className={!validRegEmail ? "border-not-valid" : ""}
                    onChange={(e) => handleRegEmail(e.target.value)}
                    type="email"
                    placeholder="Enter email"
                    />
                    {!validRegEmail ? (
                    <Form.Text className="text-not-valid">
                        Enter a valid email
                    </Form.Text>
                    ) : (
                    <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                    </Form.Text>
                    )}
                </Form.Group>
                <div className="row text-label">
                    <div className="col-12 text-right pt-4">
                    <Link className="sign-up-btn" to="/register">Sign In</Link>
                    </div>
                </div>
                <div className="text-center pt-5">
                    <Button
                    className="sign-in-btn"
                    onClick={sendRecovery}
                    variant="primary"              
                    >
                    Recover Password
                    </Button>
                </div>
                </Form>
            </div>
        </div>
    )
}
export default RecoverPasswordPage;