export const exampleRequest = {
    "input": {
        "street": "301 N High st side",
        "city": "Hillsboro",
        "state": "OH",
        "zipCode": "41533",
        "fields": "rate"
    },
    "addressMatches": [
        {
            "address": {
                "address": "301 High St N, Hillsboro, OH 45133",
                "street1": "301 High St N ",
                "city": "Hillsboro",
                "county": "Highland County",
                "state": "OH",
                "zipCode": "45133"
            },
            "coordinates": {
                "lat": 39.205416,
                "lng": -83.610975
            },
            "schoolDistricts": {
                "unified": {
                    "name": "Hillsboro City School District",
                    "lea_code": "3904412",
                    "grade_low": "PK",
                    "grade_high": "12"
                }
            },
            "localInfo": {
                "ohLocal": {
                    "municipality": {
                        "name": "Hillsboro",
                        "eeer": "EE",
                        "rate": 0.015,
                        "effectiveDate": "20020101"
                    },
                    "schoolDistrict": {
                        "name": "Hillsboro CSD",
                        "eeer": "EE",
                        "odeNumber": 44123,
                        "schoolDistrictID": 3604,
                        "rate": 0.01,
                        "effectiveDate": "19920101"
                    }
                }
            }
        }
    ]
};
