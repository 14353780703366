import React from 'react';
import ContactUsContainer from '../containers/ContactUsContainer';

class ContactUs extends React.Component {
  render(){
    return(
      <>
        <ContactUsContainer />
      </>
    );
  }
}

export default ContactUs;
