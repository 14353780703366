import React from "react";
import { Menu, Sidebar } from "semantic-ui-react";
import PayRateLogoW from "../assets/pay-rate-logo-w.png";
import { Link, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import { SetUser } from "../api/axiosBase";

import "../styles/side-bar.scss";

const SideBar = ({ history }) => {
  const logout = () => {
    localStorage.removeItem("token");
    SetUser();
    history.push('/');
    window.location.reload();
  };
  return (
    <div id="SideBar">
      <Sidebar
        as={Menu}
        animation="push"
        direction="left"
        icon="labeled"
        inverted
        vertical
        visible={true}
        width="wide"
        className="side-bar-body"
      >
        <Link
          className="custom-link"
          to={{
            pathname: "/",
            state: { prevPath: "/" },
          }}
        >
          <img className="header-img" src={PayRateLogoW} alt="" />
        </Link>
        <Link
          className="custom-link"
          to={{
            pathname: "/",
            state: { prevPath: "/" },
          }}
        >
          <Menu.Item className="menu-item" as="span">
            Dashboard
          </Menu.Item>
        </Link>
        <Link
          className="custom-link"
          to={{
            pathname: "/batchprocessor",
            state: { prevPath: "/" },
          }}
        >
          <Menu.Item className="menu-item" as="span">
            Batch Processing
          </Menu.Item>
        </Link>
        <Link
          className="custom-link"
          to={{
            pathname: "/payment",
            state: { prevPath: "/" },
          }}
        >
          <Menu.Item className="menu-item" as="span">
            Payment
          </Menu.Item>
        </Link>

        <Link
          className="custom-link"
          to={{
            pathname: "/account",
            state: { prevPath: "/" },
          }}
        >
          <Menu.Item className="menu-item" as="span">
            Account
          </Menu.Item>
        </Link>

        <div className="text-left">
          <Link
            className="custom-link"
            to={{
              pathname: "/",
              state: { prevPath: "/" },
            }}
          >
            <Button className="signout-btn" onClick={logout} type="submit">
              Log Out
            </Button>
          </Link>
          <hr />
          <Link
            className="custom-link"
            to={{
              pathname: "/terms-of-use",
              state: { prevPath: "/" },
            }}
          >
            <Button className="legal-btn">
              Terms of use
            </Button>
          </Link>
          <Link
            className="custom-link"
            to={{
              pathname: "/privacy-policy",
              state: { prevPath: "/" },
            }}
          >
            <Button className="legal-btn">
              Privacy policy
            </Button>
          </Link>
        </div>
      </Sidebar>
    </div>
  );
};

export default withRouter(SideBar);
