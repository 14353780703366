import React from "react";
import Account from "../components/dashboard/Account";

class AccountContainer extends React.Component {
  render() {
    return <Account />;
  }
}

export default AccountContainer;
