export const paLocal = {
    paLocal: {
        taxCollectionDistrict: {
            taxCollectionDistrictName: "CHESTER TAX COLLECTION DISTRICT",
            psdCode: "150601",
            county: "CHESTER",
            municipality: "EAST COVENTRY TWP",
            municipalityID: 150335,
            schoolDistrict: "OWEN J ROBERTS S D",
            schoolDistrictID: 146603,
            eeer: "EE",
            municipalNonResidentEITRate: 0.01,
            municipalResidentEITRate: 0.0075,
            municipalResidentEITRateEffDate: "20110101",
            schoolDistrictEITRate: 0.005,
            eitCollector: {
                name: "Keystone Collections Group",
                addressLine1: "546 Wendel Road",
                city: "Irwin",
                state: "PA",
                zipCode: "15642",
                phone: "(724) 978-0300",
                fax: "(724) 978-0339",
                url: "www.keystonecollects.com",
            },
            municipalLSTCollector: {
                name: "Keystone Collections Group",
                addressLine1: "546 Wendel Road",
                city: "Irwin",
                state: "PA",
                zipCode: "15642",
                phone: "(724) 978-0300",
                fax: "(724) 978-0339",
                url: "www.keystonecollects.com",
            }
        },
    }
};
