export const exampleRequest = {
    "input": {
        "street": "4685 Peoria St",
        "city": "Denver",
        "state": "CO",
        "zipCode": "80239",
        "fields": "minwage"
    },
    "addressMatches": [
        {
            "address": {
                "address": "4685 Peoria St, Denver, CO 80239-4811",
                "street1": "4685 Peoria St ",
                "city": "Denver",
                "county": "Denver County",
                "state": "CO",
                "zipCode": "80239",
                "zip4": "4811"
            },
            "coordinates": {
                "lat": 39.781243,
                "lng": -104.848602
            },
            "schoolDistricts": {
                "unified": {
                    "name": "Denver County School District 1",
                    "lea_code": "0803360",
                    "grade_low": "PK",
                    "grade_high": "12"
                }
            },
            "minimumWage": {
                "federal": {
                    "state": "Federal",
                    "wage1": 7.25,
                    "tippedWage1": 2.13,
                    "effectiveDate": "20090724"
                },
                "state": {
                    "state": "CO",
                    "wage1": 12.32,
                    "tippedWage1": 9.3,
                    "effectiveDate": "20210101"
                },
                "city": {
                    "state": "CO",
                    "locality": "Denver",
                    "wage1": 14.77,
                    "tippedWage1": 11.75,
                    "effectiveDate": "20210101"
                }
            }
        }
    ]
};
