import React, { useState } from "react";
import { Form, Button, Col } from "react-bootstrap";
import { usStatesHash } from "../../assets/UsStatesHash";

import "../../styles/live-demo/demo-form-container.scss";

const DemoForm = ({ setAddress1, setAddress2, setCity, setState, setZip }) => {
  const [actualAddress1, setActualAdress1] = useState("");
  const [actualAddress2, setActualAdress2] = useState("");
  const [actualCity, setActualCity] = useState("");
  const [actualState, setActualState] = useState("");
  const [actualZip, setActualZip] = useState("");

  function handleSubmitClick(e) {
    e.preventDefault();
    setAddress1(actualAddress1);
    setAddress2(actualAddress2);
    setCity(actualCity);
    setState(actualState);
    setZip(actualZip);
  }
  return (
    <div id="DemoFormContainer" className="demo-form-container font-weight-bold mx-5 my-5">
      <p>
        {" "}
        Test Payrate's accuracy with this demo
      </p>
      <Form>
        <Form.Group controlId="formGridAddress1">
          <Form.Label>Address Line 1</Form.Label>
          <Form.Control
            onChange={(e) => setActualAdress1(e.target.value)}
            placeholder="1234 Main St"
          />
        </Form.Group>
        <Form.Group controlId="formGridAddress2">
          <Form.Label>Address Line 2</Form.Label>
          <Form.Control
            onChange={(e) => setActualAdress2(e.target.value)}
            placeholder="Apartment, studio, or floor"
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>City</Form.Label>
            <Form.Control onChange={(e) => setActualCity(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>State</Form.Label>
            <Form.Control
              as="select"
              defaultValue=""
              onChange={(e) => setActualState(e.target.value)}
            >
              <option value="">Choose...</option>
              {usStatesHash.map(({ name, abbreviation }) => (
                <option key={abbreviation} value={abbreviation}>
                  {" "}
                  {name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridZip">
            <Form.Label>Zip</Form.Label>
            <Form.Control onChange={(e) => setActualZip(e.target.value)} />
          </Form.Group>
        </Form.Row>
        <Button
          className="try-btn"
          variant="light"
          type="submit"
          onClick={handleSubmitClick}
        >
          Try it now
        </Button>
      </Form>
    </div>
  );
};

export default DemoForm;
