import React from "react";
import '../styles/privacy-policy/privacy-policy.scss';

const PrivacyPolicy = () => {  
    return(      
      <div id="PrivacyPolicy" className="mainContent">
          <h1>PRIVACY POLICY</h1>
          <p className="c18"><span className="c8">We are committed to provide you with high-quality services and to respect your privacy highly. This Privacy Policy describes our policies and procedures on collecting, using, and disclosing your information when you use our websites, programs or services. &nbsp;</span></p>
          <ol className="c7 lst-kix_7iltzwgr8ln0-0 start" start="1">
              <li className="c11 li-bullet-0"><span className="c4 c3">GENERAL</span></li>
          </ol>
          <ol className="c7 lst-kix_vcatdoueysiw-0 start" start="1">
              <li className="c6 li-bullet-0"><span className="c2">&nbsp;Please read this privacy policy carefully before using Our Site. </span></li>
              <li className="c6 li-bullet-0"><span className="c8">&nbsp;</span><span className="c3">Definitions</span></li>
          </ol>
          <p className="c18"><span className="c8">&ldquo;</span><span className="c3">We</span><span className="c8">,&quot; &quot;</span><span className="c3">Us</span><span className="c8">&rdquo;, &ldquo;</span><span className="c3">Our</span><span className="c8">&rdquo;, means </span><span className="c3">Pinpoint Technology Solutions LLC</span><span className="c2">, Our subsidiaries and affiliates, and any of our respective agents and third-party service providers (&ldquo;service providers&rdquo;).</span></p>
          <p className="c18"><span className="c8">&ldquo;</span><span className="c3">You</span><span className="c2">&quot; means the individual accessing or using the Service, or the Company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</span></p>
          <ol className="c7 lst-kix_vcatdoueysiw-0" start="3">
              <li className="c6 li-bullet-0"><span className="c8">&nbsp;</span><span className="c3">Scope</span></li>
          </ol>
          <ol className="c7 lst-kix_upykc4f5hg3k-0 start" start="1">
              <li className="c19 li-bullet-0"><span className="c2">&nbsp;This privacy policy covers:</span></li>
          </ol>
          <ol className="c7 lst-kix_mxnsliuumgmy-0 start" start="1">
              <li className="c9 li-bullet-0"><span className="c2">&nbsp;visitors to our Website (&ldquo;Guests&rdquo;); and</span></li>
              <li className="c9 li-bullet-0"><span className="c2">&nbsp;Registered users of the Platform.</span></li>
          </ol>
          <ol className="c7 lst-kix_upykc4f5hg3k-0" start="2">
              <li className="c19 li-bullet-0"><span className="c2">&nbsp;A registered user of the Platform may provide their personal information to us to obtain our services from Our Site.</span></li>
          </ol>
          <ol className="c7 lst-kix_7iltzwgr8ln0-0" start="2">
              <li className="c11 li-bullet-0"><span className="c3 c4">COLLECTION OF INFORMATION</span></li>
          </ol>
          <ol className="c7 lst-kix_5jgjpru4022i-0 start" start="1">
              <li className="c6 li-bullet-0"><span className="c2">&nbsp;We collect your personal information to maximize high-quality service delivery to you, provide you with any additional or special information about our services, and protect your account and personal information. </span></li>
              <li className="c6 li-bullet-0"><span className="c2">&nbsp;We may collect the following kinds of information when you, your colleagues, or other users access the Service: </span></li>
          </ol>
          <ol className="c7 lst-kix_yupizdh22lmd-0 start" start="1">
              <li className="c0 li-bullet-0"><span className="c2">your contact information, such as full name and email address;</span></li>
              <li className="c0 li-bullet-0"><span className="c2">The Content, communications, and other information you provide when you use Our Service, including when you sign up for an account. This can include information in or about the Content you provide; </span></li>
              <li className="c0 li-bullet-0"><span className="c2">user communications, feedback, suggestions, and ideas sent to you;</span></li>
              <li className="c0 li-bullet-0"><span className="c2">credit information;</span></li>
              <li className="c0 li-bullet-0"><span className="c2">billing information; and</span></li>
              <li className="c0 li-bullet-0"><span className="c2">Information that you provide when you engage platform support regarding the Service.</span></li>
          </ol>
          <p className="c18"><span className="c2">You represent and warrant that you have sought the consent of any individual whose information you provide to us.</span></p>
          <ol className="c7 lst-kix_5jgjpru4022i-0" start="3">
              <li className="c6 li-bullet-0"><span className="c2">&nbsp;We may collect personal information about you from the information you provide to us when you fill out an Application or other forms on our site. We may also receive personal information about you from third-party services such as financial institutions and credit bureaus. Lastly, we may also collect personal information from individuals whose identity you share with us. </span></li>
              <li className="c6 li-bullet-0"><span className="c2">&nbsp;You permit us to collect your personal information at any time, before, during, and after engaging in a business relationship with us. &nbsp;</span></li>
              <li className="c6 li-bullet-0"><span className="c2">&nbsp;Our servers also collect information from you, such as your domain name, websites you visit, and Internet Protocol address. This information is not personally identifiable. However, when you respond to questions or communicate with us, your personal information may be collected. &nbsp;</span></li>
          </ol>
          <p className="c10"><span className="c2"></span></p>
          <ol className="c7 lst-kix_7iltzwgr8ln0-0" start="3">
              <li className="c11 li-bullet-0"><span className="c3">USE</span><span className="c8">&nbsp;</span><span className="c3">OF</span><span className="c8">&nbsp;</span><span className="c3">INFORMATION</span></li>
          </ol>
          <ol className="c7 lst-kix_qxl1aouwtadn-0 start" start="1">
              <li className="c13 li-bullet-0"><span className="c2">&nbsp;Where it is in our legitimate interest to do so, We may use your personal information to:</span></li>
          </ol>
          <ol className="c7 lst-kix_alrs1v1z5fle-0 start" start="1">
              <li className="c0 li-bullet-0"><span className="c2">To effectively manage Your Account: to manage Your registration as a user of the Our services;</span></li>
              <li className="c0 li-bullet-0"><span className="c2">To provide you with details about our products and services by email, text, phone and through other communication methods;</span></li>
              <li className="c0 li-bullet-0"><span className="c2">carry out financial and identity checks, fraud prevention checks, regulatory checks, and credit checks;</span></li>
              <li className="c0 li-bullet-0"><span className="c2">to carry out product development, statistical analysis, and market research;</span></li>
              <li className="c0 li-bullet-0"><span className="c2">to develop and improve our services ;</span></li>
              <li className="c0 li-bullet-0"><span className="c2">to update our records;</span></li>
              <li className="c0 li-bullet-0"><span className="c2">to carry out checks required by applicable regulation or regulatory guidance;</span></li>
              <li className="c0 li-bullet-0"><span className="c2">to improve Our relationship with you by making the Website available to you in a User-friendly way, and to identify the services which you may be interested in;</span></li>
              <li className="c0 li-bullet-0"><span className="c2">for customer service, including answering questions and responding to feedback and complaints;</span></li>
              <li className="c0 li-bullet-0"><span className="c2">for any other specific purposes in relation to your activities via the Platform;</span></li>
              <li className="c0 li-bullet-0"><span className="c2">Where you have given us consent, we shall provide you with information about any new services, promotions, and other information which we think will be of interest to you. You can withdraw your consent at any time, but without affecting the lawfulness of processing based on consent before its withdrawal. You can update your details or change your privacy preferences by contacting us via the details given in the &quot;Contacting Us&quot; section above.</span></li>
          </ol>
          <ol className="c7 lst-kix_qxl1aouwtadn-0" start="2">
              <li className="c13 li-bullet-0"><span className="c2">We may use your credit-card information to ascertain your eligibility for our services and conduct a necessary risk assessment. We may collect this information at any time before, during, and after our business relationship.</span></li>
              <li className="c13 li-bullet-0"><span className="c2">&nbsp;We use your email address to make communicate with you. Upon your consent, we may direct electronic email communication to you about our services or market new services. We may also ask to seek your feedback on the services we offer. You have an option to opt-out of such electronic communication. Lastly, we may track your email activity to check the effectiveness of the communication. &nbsp;</span></li>
          </ol>
          <ol className="c7 lst-kix_7iltzwgr8ln0-0" start="4">
              <li className="c11 li-bullet-0"><span className="c4 c3">SHARING PERSONAL INFORMATION </span></li>
          </ol>
          <ol className="c7 lst-kix_cr1x8qnw3ql3-0 start" start="1">
              <li className="c6 li-bullet-0"><span className="c2">&nbsp;We won&rsquo;t provide your personal information to other companies for their marketing purposes unless you have given us your consent. However, we may aggregate anonymous information based on your personal information and disclose this to advertisers and other third parties.</span></li>
          </ol>
          <ol className="c7 lst-kix_jhvm5kp3mbln-1 start" start="2">
              <li className="c0 li-bullet-0"><span className="c2">We may disclose your personal information to third parties where it is in our legitimate interest to do so, including the following reasons: </span></li>
              <li className="c0 li-bullet-0"><span className="c2">we may share your information with analytics and search engine providers that assist us in the improvement and optimization of our site;</span></li>
              <li className="c0 li-bullet-0"><span className="c2">we may share your personal information with companies and other third parties performing services on our behalf (for example, credit reference agencies, customer relationship management providers, or other service providers) who will only use the information to provide that Service. We may also share your personal information with other members of our corporate group. </span></li>
              <li className="c0 li-bullet-0"><span className="c2">we may share alerts and information derived from identity verification checks with third parties for anti-money laundering and fraud prevention; </span></li>
              <li className="c0 li-bullet-0"><span className="c2">we may disclose your personal information on request to the police or any other regulator or government authority to fulfill our regulatory responsibilities, to help prevent or detect fraud or any other type of crime, or for any other reasonable purpose identified by the relevant authority; </span></li>
              <li className="c0 li-bullet-0"><span className="c2">Save as set out in this privacy policy; we will not sell or disclose your data to any third party;</span></li>
              <li className="c0 li-bullet-0"><span className="c2">&nbsp;We may share your information in the event of a merger, acquisition, or sale of our assets. We shall communicate with you in the event of such a change.</span></li>
          </ol>
          <ol className="c7 lst-kix_jhvm5kp3mbln-0 start" start="4">
              <li className="c1 li-bullet-0"><span className="c3">TRACKING TECHNOLOGIES AND COOKIES</span></li>
          </ol>
          <ol className="c7 lst-kix_jhvm5kp3mbln-1 start" start="2">
              <li className="c0 li-bullet-0"><span className="c2">&nbsp;We use cookies and similar tracking technologies to track the activity on Our Service and store certain information. You can instruct your browser to refuse all Cookies or to indicate when a cookie is being sent. However, if you do not accept Cookies, You may not be able to use some parts of our Program or Site.</span></li>
              <li className="c0 li-bullet-0"><span className="c2">Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on your personal computer or mobile device when you go offline, while Session Cookies are deleted as soon as you close your web browser. </span></li>
              <li className="c0 li-bullet-0"><span className="c2">We use both session and persistent cookies for the purposes set out below:</span></li>
          </ol>
          <ol className="c7 lst-kix_wnz9dsd7par4-0 start" start="1">
              <li className="c0 li-bullet-0"><span className="c2">Necessary / Essential Cookies</span></li>
          </ol>
          <p className="c5"><span className="c2">These Cookies are essential to provide you with services available through the Website and enable you to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services you have asked for cannot be provided, and we only use these Cookies to give you those services.</span></p>
          <ol className="c7 lst-kix_wnz9dsd7par4-0" start="2">
              <li className="c0 li-bullet-0"><span className="c2">Cookies Policy / Notice Acceptance Cookies</span></li>
          </ol>
          <p className="c5"><span className="c2">These Cookies identify if users have accepted the use of cookies on the Website.</span></p>
          <ol className="c7 lst-kix_wnz9dsd7par4-0" start="3">
              <li className="c0 li-bullet-0"><span className="c2">Functionality Cookies</span></li>
          </ol>
          <p className="c5"><span className="c2">These cookies allow us to remember choices you make when you use the Website, such as remembering your login details or language preference. The purpose of these cookies is to provide you with more personal experience and to avoid you having to re-enter your preferences every time you use the Website.</span></p>
          <ol className="c7 lst-kix_wnz9dsd7par4-0" start="4">
              <li className="c0 li-bullet-0"><span className="c2">Tracking and Performance Cookies</span></li>
          </ol>
          <p className="c5"><span className="c2">These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the data collected is typically linked to a pseudonymous identifier associated with the Device You use to access the Website. We may also use these cookies to test new advertisements, pages, features, or new functionality of the Website to see how our users react to them.</span></p>
          <p className="c10"><span className="c2"></span></p>
          <ol className="c7 lst-kix_jhvm5kp3mbln-0" start="5">
              <li className="c1 li-bullet-0"><span className="c3">Analytics</span></li>
          </ol>
          <p className="c10"><span className="c4 c3"></span></p>
          <ol className="c7 lst-kix_jhvm5kp3mbln-1" start="5">
              <li className="c0 li-bullet-0"><span className="c2">&nbsp;We may use third-party Service providers to monitor and analyze the use of our Website.</span></li>
              <li className="c0 li-bullet-0"><span className="c2">One such service provider is Google. Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Program and Site. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its advertising network.</span></li>
              <li className="c0 li-bullet-0"><span className="c8">You may opt-out of certain Google Analytics features through your mobile device settings, such as your device advertising settings, or by following the instructions provided by Google in their Privacy Policy: </span><span className="c8 c12"><a className="c21" href="https://www.google.com/url?q=https://policies.google.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1616146435026000&amp;usg=AOvVaw0yvwRRdDgnGxI-74Vh_LqE">https://policies.google.com/privacy</a></span><span className="c2">.</span></li>
              <li className="c0 li-bullet-0"><span className="c8">For more information on Google&#39;s privacy practices, please visit the Google Privacy &amp; Terms web page: </span><span className="c12 c8"><a className="c21" href="https://www.google.com/url?q=https://policies.google.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1616146435026000&amp;usg=AOvVaw0yvwRRdDgnGxI-74Vh_LqE">https://policies.google.com/privacy</a></span><span className="c2">.</span></li>
          </ol>
          <p className="c10"><span className="c2"></span></p>
          <ol className="c7 lst-kix_jhvm5kp3mbln-0" start="6">
              <li className="c1 li-bullet-0"><span className="c4 c3">LINKS TO OTHER WEBSITES</span></li>
          </ol>
          <ol className="c7 lst-kix_xbkh5jbilemk-0 start" start="1">
              <li className="c20 li-bullet-0"><span className="c3">&nbsp;</span><span className="c2">Our Program and Site may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party&#39;s site. We strongly advise you to review the Privacy Policy of every site you visit.</span></li>
              <li className="c20 li-bullet-0"><span className="c3">&nbsp;</span><span className="c2">We have no control over and assume no responsibility for the Content, privacy policies, or practices of any third-party sites or services.</span></li>
          </ol>
          <ol className="c7 lst-kix_jhvm5kp3mbln-0" start="7">
              <li className="c1 li-bullet-0"><span className="c4 c3">SECURITY OF YOUR PERSONAL INFORMATION</span></li>
          </ol>
          <ol className="c7 lst-kix_61jrq37k6olb-0 start" start="1">
              <li className="c6 li-bullet-0"><span className="c2">&nbsp;The security of Your Personal Data is important to us but remember that no transmission method over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect Your Personal Data, we cannot guarantee its absolute security.</span></li>
              <li className="c6 li-bullet-0"><span className="c2">&nbsp;We restrict access to the information obtained from our websites and web pages to our employees, agents, Affiliates, Partners and contractors. We maintain physical, electronic, and procedural safeguards designed to protect personal information to the extent reasonably possible.</span></li>
          </ol>
          <ol className="c7 lst-kix_jhvm5kp3mbln-0" start="8">
              <li className="c1 li-bullet-0"><span className="c4 c3">CONSENT REQUESTS AND PREFERENCES</span></li>
          </ol>
          <ol className="c7 lst-kix_f1ff1elfy1yp-0 start" start="1">
              <li className="c6 li-bullet-0"><span className="c2">&nbsp;You may request us to identify the personal information we have collected and kept in our servers. To make such a request, contact us through our email provided below.</span></li>
              <li className="c6 li-bullet-0"><span className="c2">&nbsp;You may contact us to cancel, withdraw, or restrict the amount and type of information we collect and keep. We may keep on using some of your personal information even after your withdrawal if the information is necessary to run your account or fulfill legal obligations. We may also stop providing services that we would only give if we had the information you have withdrawn.</span></li>
          </ol>
          <p className="c10"><span className="c2"></span></p>
          <ol className="c7 lst-kix_jhvm5kp3mbln-0" start="9">
              <li className="c1 li-bullet-0"><span className="c4 c3">&nbsp;Changes to this Privacy Policy</span></li>
          </ol>
          <ol className="c7 lst-kix_cr0oxql4jyzv-0 start" start="1">
              <li className="c13 li-bullet-0"><span className="c2">&nbsp;We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</span></li>
              <li className="c13 li-bullet-0"><span className="c2">&nbsp;We will let you know via email or a prominent notice on Our Program and Site before or when the changes become effective and update the &quot;Last Updated&quot; date at the top of this Privacy Policy.</span></li>
              <li className="c13 li-bullet-0"><span className="c2">&nbsp;You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are significant when they are posted on this page.</span></li>
          </ol>
          <ol className="c7 lst-kix_jhvm5kp3mbln-0" start="10">
              <li className="c1 li-bullet-0"><span className="c3">&nbsp;CONTACT US</span></li>
          </ol>
          <p className="c14"><span className="c2">If you have any questions about the provisions in this Privacy Policy, You can contact us:</span></p>
          <p className="c14"><span className="c8">By email etc.: [provide the email address here and any other Channel for contacting]</span></p>
      </div>
    )  
}

export default PrivacyPolicy;
