import React from "react";
import JSONPretty from "react-json-pretty";
import { exampleRequest } from "../../assets/exampleLocalWithholdingRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import "../../styles/home-page/custom-theme.css";

const ResultExample = () => {
  return (
    <div className="row mx-auto example-background">
      <div className="col-6 p-5">
        <div className="top-background shadow-sm">
          <FontAwesomeIcon
            className="pl-1"
            style={{ color: "#f2555c" }}
            icon={faCircle}
          />
          <FontAwesomeIcon
            className="pl-1"
            style={{ color: "#efc778" }}
            icon={faCircle}
          />
          <FontAwesomeIcon
            className="pl-1"
            style={{ color: "#b5e08d" }}
            icon={faCircle}
          />
        </div>
        <div className="example-json-container shadow-sm">
          <JSONPretty id="json-pretty" data={exampleRequest} />
        </div>
      </div>
      <div className="col-6 p-5">
        <h3 className="example-title">Local withholding Rates</h3>
        <p className="example-p">
          Which local withholding rates an employee is subject to can be
          difficult to determine. An employee's mailing address often isn't
          enough to determine which jurisdictions they physically reside in.
          PayRate takes the uncertainty out of tax assignment.
        </p>
        <p className="example-p">
          PayRate provides an industry-leading Geocoding process that converts a
          postal address in Latitude/Longitude coordinates to provide rooftop
          accuracy. From there it determines which local jurisdictions apply.
        </p>
        <h5 className="example-subtitle">
          Easily look up employee and employer taxes.
        </h5>
        <div className="row">
          <div className="col-6">
            <div>
              <p className="item-text-ex align-middle">Resident Rates</p>
            </div>
            <div>
              <p className="item-text-ex align-middle">Nonresident Rates</p>
            </div>
            <div>
              <p className="item-text-ex align-middle">Default Rates</p>
            </div>
            <div>
              <p className="item-text-ex align-middle">Flat Tax Amounts</p>
            </div>
            <div>
              <p className="item-text-ex align-middle">Maximum Tax Amounts</p>
            </div>
          </div>
          <div className="col-6">
            <div>
              <p className="item-text-ex align-middle">Mental Health Taxes</p>
            </div>
            <div>
              <p className="item-text-ex align-middle">School District Taxes</p>
            </div>
            <div>
              <p className="item-text-ex align-middle">Tax Exemption Limits</p>
            </div>
            <div>
              <p className="item-text-ex align-middle">PSD Codes</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultExample;
