import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "../styles/footer.scss";

const Footer = () => {
    return (
        <div className="clearfix" id="Footer">
            <div className="text-center pb-5 pt-2">
            <Link
                to={{
                pathname: "/terms-of-use",
                state: { prevPath: "/" },
                }}
            >
                <Button variant="link" className="link-button">
                    Terms of use
                </Button>
            </Link>
            <Link
                to={{
                pathname: "/privacy-policy",
                state: { prevPath: "/" },
                }}
            >
                <Button variant="link" className="link-button">
                    Privacy policy
                </Button>
            </Link>
            </div>
        </div>
    );
}

export default Footer;