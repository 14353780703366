import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import Loader from "react-loader-spinner";

import CardInput from "../stripe-checkout/CardInput";

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Alert } from "react-bootstrap";
import { TransactionalAPI } from "../../api/axiosBase";

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
    margin: "35vh auto",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
  },
  div: {
    display: "flex",
    flexDirection: "row",
    alignContent: "flex-start",
    justifyContent: "space-between",
  },
  button: {
    margin: "2em auto 1em",
  },
});
const Subscription = ({setUserInfo}) => {
  //stripe
  const stripe = useStripe();
  const elements = useElements();

  const classes = useStyles();

  const [billingEmail, setBillingEmail] = useState("");
  const [creatingStatus, setCreatingStatus] = useState(1); // 1: initial form, 2: Creating customer, 3: Creating subscription, 5: Created, 6: Error
  const [error, setError] = useState("");

  const createCustomer = () => {
    setCreatingStatus(2);
    console.log("billing email", billingEmail);
    TransactionalAPI
      .post(`create-customer`, {
        email: billingEmail
      })
      .then((response) => {
        console.log("response createcus", response);
        
        createPaymentMethod(
          elements.getElement(CardElement),
          response.data.customer.id,
          process.env.REACT_APP_STRIPE_PRICE_ID
        );
      })
      .catch((err) => {
        console.error(err);
        setError(`Error creating the billing information, ${err}`);
        setCreatingStatus(6);
      });;
  };

  const createPaymentMethod = (cardElement, customerId, priceId) => {
    if (!stripe || !elements || !cardElement) {
      setError("No payment information was passed");
      setCreatingStatus(6);
      return;
    }
    
    setCreatingStatus(3);
    stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement,
      })
      .then((result) => {
        if (result.error) {
          console.log("Error creating payment method", result.error);
          setError(!!result.error.code ? `${result.error.code}: ${result.error.message}` : result.error);
          setCreatingStatus(6);
        } else {
          createSubscription(customerId, result.paymentMethod.id, priceId);
        }
      })
      .catch((err) => {
        setError(`Error creating the costumer information, ${err}`);
        setCreatingStatus(6);
      });
  };

  const createSubscription = (customerId, paymentMethodId, priceId) => {
    TransactionalAPI
      .post(`create-subscription`, {
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
      })
      .then((response) => {
        console.log("response", response.data.items[0].id);        
        onSubscriptionComplete(response);
      })
      .catch((err) => {
        console.error(err);
        setError(`Error creating the costumer information, ${err.response.data.error.message}`);
        setCreatingStatus(6);
      });
  };

  const onSubscriptionComplete = (response) => {
    const subscription_item_id  = response.data.items[0].id;     
    const status = response.data.status === "active";

    TransactionalAPI
      .post(`handleAccountStatus`, {
        status: status,
        subscriptionItemId: subscription_item_id,
      })
      .then((response) => {
        updateUserInfo();
        setCreatingStatus(4);
        console.log("Complete", response)
      });
  
  };

  const updateUserInfo = () => {    
    TransactionalAPI
      .get(`userInfo`)
      .then((response) => {
        if (response.data) {
          setUserInfo(response.data);
          setCreatingStatus(5);
        }
      });
  }
  
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        {creatingStatus === 2 && 
          <div className="text-center card">
            <h2>Creating the customer information</h2>
            <h2><small>Please wait...</small></h2>
            <Loader
              type="ThreeDots"
              color="#39517a"
              height={200}
              width={100}
            />
          </div>
        }
        {creatingStatus === 3 && 
          <div className="text-center card">
            <h2>Creating the subscription information</h2>
            <h2><small>Please wait...</small></h2>
            <Loader
              type="ThreeDots"
              color="#39517a"
              height={200}
              width={100}
            />
          </div>
        }
        {creatingStatus === 4 && 
          <div className="text-center card">
            <h2>Updating user information</h2>
            <h2><small>Please wait...</small></h2>
            <Loader
              type="ThreeDots"
              color="#39517a"
              height={200}
              width={100}
            />
          </div>
        }
        {creatingStatus === 5 && 
          <Alert variant='success'>The payment information has been updated successfully!</Alert>
        }
        {(creatingStatus === 6 && !!error) && (
          <Alert variant='danger'>{`Error: ${error}`}</Alert>
        )}

        <TextField
          label="Email"
          id="outlined-email-input"
          helperText={`Email you'll receive updates and receipts on`}
          margin="normal"
          variant="outlined"
          type="email"
          required
          value={billingEmail}
          onChange={(e) => setBillingEmail(e.target.value)}
          fullWidth
        />
        <CardInput />
        <div className={classes.div}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={createCustomer}
            disabled={(creatingStatus === 2 || creatingStatus === 3)}
          >
            Subscribe
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default Subscription;
