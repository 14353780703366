import React from "react";
import { Form, Button } from "react-bootstrap";
import "../../styles/dashboard/dashboard.scss";

const Account = () => {
  return (
    <div id="Account">
      <div className="account-container">
        <h4>Edit Profile</h4>
        <Form>
          <Form.Group controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" placeholder="Enter your name" />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" />
            <Form.Text className="text-muted">            
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" />
          </Form.Group>          
          <div className="text-center pt-5">
            <Button className="sign-in-btn" variant="primary">
              Send
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Account;
