import React, { useEffect, useState } from "react";
import GoogleMaps from "simple-react-google-maps";
import Loader from "react-loader-spinner";

const DemoMap = ({ lat, lng, isLoading }) => {
  const [marker, setMarker] = useState({
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  });

  useEffect(() => {
    setMarker({ lat: parseFloat(lat), lng: parseFloat(lng) });
  }, [lat, lng]);
  console.log(marker);

  return (
    <div>
      {!isLoading ? (
        <GoogleMaps
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          style={{
            height: "600px",
            width: "900px",
            marginTop: "50px",
            marginBottom: "50px",
          }}
          zoom={18}
          center={marker}
          markers={marker}
        />
      ) : (
        <div className="text-center" style={{paddingTop:"25%"}}>
          <Loader
            type="ThreeDots"
            color="#39517a"
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        </div>
      )}
    </div>
  );
};

export default DemoMap;
