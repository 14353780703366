import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Subscription from "../components/stripe-subscription/Subscription";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const StripeContainer = ({setUserInfo}) => {
  return (
    <Elements stripe={stripePromise}>
      <Subscription setUserInfo={setUserInfo} />
    </Elements>
  );
};

export default StripeContainer;
