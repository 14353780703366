import React from "react";
import ApiDocumentationContainer from "../containers/ApiDocumentationContainer";

class ApiDocumentationPage extends React.Component {
  render() {
    return (
      <>
        <ApiDocumentationContainer />
      </>
    );
  }
}

export default ApiDocumentationPage;
