import React from "react";
import "../../styles/api-documentation/api-content.scss";

const ResponseObject = () => (
  <>
    <table className="table">
      <thead>
        <tr>
          <th>input</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div className="small-code text-center">street1</div>
          </td>
          <td>address line 1</td>
        </tr>
        <tr>
          <td>
            <div className="small-code text-center">street2</div>
          </td>
          <td>address line 2</td>
        </tr>
        <tr>
          <td>
            <div className="small-code text-center">city</div>
          </td>
          <td>city</td>
        </tr>
        <tr>
          <td>
            <div className="small-code text-center">state</div>
          </td>
          <td>state</td>
        </tr>
        <tr>
          <td>
            <div className="small-code text-center">zipCode</div>
          </td>
          <td>zip code</td>
        </tr>
      </tbody>
    </table>

    <h6 className="pt-5">addressMatches</h6>
    
          
    <table className="table">
      <thead>
        <tr>
          <th>address</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><div className="small-code text-center">address</div></td>
          <td>address</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">street1</div></td>
          <td>address line 1</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">street2</div></td>
          <td>address line 2</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">city</div></td>
          <td>city</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">county</div></td>
          <td>county</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">zipCode</div></td>
          <td>5 digit zip code</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">zip4</div></td>
          <td>4 digit zip code extension</td>
        </tr>
      </tbody>
    </table>

    <table className="table">
      <thead>
        <tr>
          <th>coordinates</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><div className="small-code text-center">lat</div></td>
          <td>latitude</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">lng</div></td>
          <td>longitude</td>
        </tr>
      </tbody>
    </table>

          
    <table className="table">
      <thead>
        <tr>
          <th>school districts</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><div className="small-code text-center">unified</div></td>
          <td>unified school district</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">secondary</div></td>
          <td>secondary</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">elementary</div></td>
          <td>elementary school district</td>
        </tr>
      </tbody>
    </table>

          
    <table className="table">
      <thead>
        <tr>
          <th>minimumWage</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><div className="small-code text-center">federal</div></td>
          <td>federal minimum wage</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">state</div></td>
          <td>state minimum wage</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">county</div></td>
          <td>county minimum wage</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">city</div></td>
          <td>city minimum wage</td>
        </tr>
      </tbody>
    </table>

          
    <table className="table">
      <thead>
        <tr>
          <th>localInfo</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><div className="small-code text-center">rates</div></td>
          <td>local rates</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">local rates</div></td>
          <td>Pennsylvania specific local information</td>
        </tr>
        <tr>
          <td><div className="small-code text-center">ohLocal</div></td>
          <td>Ohio specific local information</td>
        </tr>
      </tbody>
    </table>
  </>
);

export default ResponseObject;
