import React from 'react';
import { useLocation } from 'react-router';
import UserLoginContainer from '../containers/UserLoginContainer';

const Registration = ({ setLogInStatus }) => {
    const location = useLocation();
    const loadScreen = !!location.state ? location.state.loadScreen : undefined;
    return (
        <>
            <UserLoginContainer setAuthStatus={setLogInStatus} loadScreen={loadScreen} />
        </>
    )
}

export default Registration;
