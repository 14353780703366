import React, { useState, useEffect } from "react";
import HomePage from "./pages/HomePage";
import NavBar from "./components/NavBar";
import SideBar from "./components/SideBar";
// import LiveDemo from "./pages/LiveDemo";
import ContactUs from "./pages/ContactUs";
import ApiDocumentationPage from "./pages/ApiDocumentationPage";
import Registration from "./pages/Registration";
import Pricing from "./components/Pricing/Pricing";
import { Switch, Route, Redirect } from "react-router-dom";
import DashboardContainer from "./containers/DashboardContainer";
import DownloadGroupContainer from "./containers/DownloadGroupContainer";
import StripeContainer from "./containers/StripeContainer";
import AccountContainer from "./containers/AccountContainer";
import BatchProcessingContainer from "./containers/BatchProcessingContainer";
import "semantic-ui-css/semantic.min.css";
import RecoverPasswordPage from "./pages/RecoverPasswordPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import Footer from "./components/Footer";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { SetUser, TransactionalAPI } from "./api/axiosBase";

// import { BulkUploadProcessComponent } from "./components/bulk-upload/BulkUploadProcess";

const App = () => {
    const [logInStatus, setLogInStatus] = useState(false);
    const [userInfo, setUserInfo] = useState({});


    useEffect(() => {
        let loadingToken = localStorage.getItem("token");

        if (!!loadingToken) {
            console.log(`Setted User ${loadingToken}`);
            SetUser(loadingToken);
        }

        TransactionalAPI
            .get(`isUserAuth`)
            .then((response) => {
                console.log("token", localStorage);
                setLogInStatus(response.data.loggedIn);
            });
    }, []);

    useEffect(() => {
        if (logInStatus) {
            TransactionalAPI
                .get(`userInfo`)
                .then((response) => {
                    if (response.data) {
                        setUserInfo(response.data);
                    }
                });
        }

    }, [logInStatus])

    console.log("user info", userInfo);

    if (logInStatus === true) {
        return (
            <div className="row" style={{ backgroundColor: "white !important", paddingLeft: "calc(350px + 3rem)", width: "100%" }}>
                <Redirect to="/" />
                <div className="">
                    <SideBar />
                </div>
                <div className="col-12">
                    <Switch>
                        <Route
                            path="/"
                            render={() => (
                                <DashboardContainer
                                    userInfo={userInfo}
                                />
                            )}
                            exact
                        />
                        <Route
                            path="/batchprocessor"
                            render={() => <BatchProcessingContainer userInfo={userInfo} />}
                        />
                        <Route path="/payment" render={() => <StripeContainer setUserInfo={setUserInfo} />} />
                        <Route path="/account" component={AccountContainer} />
                        <Route path="/groupDownload/:transaction_group_id" render={() => <DownloadGroupContainer userInfo={userInfo} />} />
                        <Route
                            path="/terms-of-use" component={TermsOfUse}></Route>
                        <Route
                            path="/privacy-policy" component={PrivacyPolicy}></Route>
                    </Switch>
                </div>
            </div>
        );
    } else {
        return (
            <>
                <NavBar />
                <Switch>
                    <Route path="/" component={HomePage} exact />
                    {/* <Route path="/livedemo" component={LiveDemo} /> */}
                    {/* <Route path="/about" component={ContactUs} /> */}
                    <Route path="/contact-us" component={ContactUs} />
                    <Route path="/api" component={ApiDocumentationPage} />
                    <Route path="/pricing" render={() => <Pricing />} />
                    <Route
                        path="/register"
                        render={(props) => <Registration setLogInStatus={setLogInStatus} />}
                    />
                    <Route
                        path="/recover-password" component={RecoverPasswordPage}></Route>
                    <Route
                        path="/change-password/:email/:token" component={ChangePasswordPage}></Route>
                    <Route
                        path="/terms-of-use" component={TermsOfUse}></Route>
                    <Route
                        path="/privacy-policy" component={PrivacyPolicy}></Route>
                </Switch>
                <Footer />
            </>
        );
    }
};

export default App;
