import React, { useEffect, useState } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { UserTokenFactory } from '../../api/axiosBase';

const ENDPOINT = `${process.env.REACT_APP_SERVER_URL}progress`

const BulkUploadProcessReportComponent = ({ transactionGroupId, fnFinishedLoading }) => {
    const [response, setResponse] = useState("");
    const [connection, setConnection] = useState(null);

    useEffect(() => {
        if (!!transactionGroupId) {
            const newConnection = new HubConnectionBuilder()
                .withUrl(ENDPOINT, { accessTokenFactory: UserTokenFactory })
                .withAutomaticReconnect()
                .build();

            setConnection(newConnection);
            //const socket = socketIOClient(ENDPOINT);
            //socket.on(transactionGroupId, data => {
            //    setResponse(data);

            //});
        }
    }, [transactionGroupId]);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    console.log('Connected!');
                    connection.send("Watch", transactionGroupId);

                    connection.on('progress', data => {
                        setResponse(data);
                    });
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection]);


    useEffect(() => {
        if(!!response.progress && !!response.total && response.progress === response.total && !!response.result) {
            fnFinishedLoading(response.result);
        }
    }, [response])
    
  return (
    <>
        {
        !!response.progress || !!response.progress ?
            <>
                <h3>
                    {`Processed record ${response.progress} out of ${response.total}`}
                </h3>
                <h6>Feel free to close this tab, and check for your results in the dashboard...</h6>
            </>
            : <h3>Loading...</h3>
        }
    </>
  );
}

export default BulkUploadProcessReportComponent;