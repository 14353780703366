import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import "../../styles/dashboard/apikey-container.scss";

const APIKeyContainer = ({ apiKey }) => {
  const [dropDownClick, setDropDownClick] = useState(false);
  const handleDropClick = () => {
    setDropDownClick(!dropDownClick);
  };

  return (
    <div id="ApiKeyContainer">
      <div className="row">
        <div className="drop-down-btn text-left col-12" onClick={handleDropClick}>
          <h2>
            Get your credentials &nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon className="float-right" icon={dropDownClick ? faChevronDown : faChevronRight} />
          </h2>
        </div>
        <div className={`col-12 ${ dropDownClick ? "d-block" :  "d-none" }`}>
          <h5 className="mt-3">Here is your API Key</h5>
          <div className="row my-3 key-row">
            <div className="pl-5 col-7 key-span">{apiKey}</div>
            <CopyToClipboard text={apiKey}>
              <div className="col-1 key-span text-right">
                <FontAwesomeIcon icon={faCopy} />
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default APIKeyContainer;
