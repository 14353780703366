import React from "react";
import { Form, Button } from "react-bootstrap";
import "../../styles/user-registration/user-login.scss";

const Register = ({
  handleRegEmail,
  validRegEmail,
  setEmailReg,
  setPasswordReg,
  register,
  handleIsRegistered,
  setConfirmPassword,
  setNameReg,
  setChecked,
  checked
}) => {
  return (
    <div id="Login">
      <div className="login-container">
        <h4>Get started with a free account</h4>
        <Form>
          <Form.Group controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control            
              onChange={(e) => setNameReg(e.target.value)}
              type="text"
              placeholder="Enter your name"
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              className={!validRegEmail ? "border-not-valid" : ""}
              onChange={(e) => handleRegEmail(e.target.value)}
              type="email"
              placeholder="Enter email"
            />
            {!validRegEmail ? (
              <Form.Text className="text-not-valid">
                Enter a valid email
              </Form.Text>
            ) : (
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              onChange={(e) => setPasswordReg(e.target.value)}
              type="password"
              placeholder="Password"
            />
          </Form.Group>
          <Form.Group controlId="formBasicConfirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              onChange={(e) => setConfirmPassword(e.target.value)}
              type="password"
              placeholder="Password"
            />
          </Form.Group>
          <div className="row text-label">
            <div className="col-6">
              <Form.Group className="text-label" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="I agree to Payrate's Terms of Use"
                  onChange={() => setChecked(!checked)}
                />
              </Form.Group>
            </div>
            <div className="col-6 text-right pt-4">
              Have an account?
              <Button className="sign-up-btn" onClick={handleIsRegistered}>
                Sign In
              </Button>
            </div>
          </div>
          <div className="text-center pt-5">
            <Button
              className="sign-in-btn"
              onClick={register}
              variant="primary"              
            >
              Create account
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Register;
