import React from "react";
import '../styles/terms-of-use/terms-of-use.scss';

const TermsOfUse = () => {  
    return(
        <div id="TermsOfUse" className="mainContent">
            <h1>TERMS OF USE</h1>
            <p className="c1"><span className="c5">Please read these terms of use (&quot;terms of use&quot;, &quot;terms&quot;) carefully before using </span><span className="c4">pay-rate.com</span><span className="c5">&nbsp;website (&ldquo;website&rdquo;, &quot;service&quot;) operated by </span><span className="c4">Pinpoint Technology Solutions LLC</span><span className="c0">&nbsp;(&quot;us&quot;, &#39;we&quot;, &quot;our&quot;). </span></p>
            <p className="c1"><span className="c3">Conditions of use</span></p>
            <p className="c1"><span className="c10">The service is provided on an &quot;AS IS,&quot; &quot;as available&quot; basis. Neither our Company nor its affiliates, subsidiaries, or designees nor each of their respective officers, directors, employees, agents, third-party content providers, designers, contractors, distributors, merchants, sponsors, licensors, or the like (collectively &quot;affiliates&quot;) warrant that use of the service will be uninterrupted or error-free. Neither our Company nor its affiliates warrant the accuracy, integrity or completeness of the content provided on the Website. You expressly agree that use of the service is at your sole risk.</span></p>
            <p className="c1"><span className="c0">By using this service, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to leave the website accordingly. We only grant use and access of this website, its products, and its services to those who have accepted its terms.</span></p>
            <p className="c1"><span className="c3">Privacy policy</span></p>
            <p className="c1"><span className="c0">Before you continue using our website, we advise you to read our privacy policy below regarding our user data collection. It will help you better understand our practices.</span></p>
            <p className="c1"><span className="c3">Age restriction</span></p>
            <p className="c1"><span className="c0">You must be at least 18 (eighteen) years of age before you can use this website. By using this website, you warrant that you are at least 18 years of age and you may legally adhere to this Agreement. We assume no responsibility for liabilities related to age misrepresentation.</span></p>
            <p className="c1"><span className="c3">Intellectual property</span></p>
            <p className="c1"><span className="c5">You agree that all materials, products, and services provided on this website are the property of </span><span className="c4">Pinpoint Technology Solutions LLC</span><span className="c0">, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute the intellectual property in any way, including electronic, digital, or new trademark registrations.</span></p>
            <p className="c1"><span className="c0">You grant us a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement.</span></p>
            <p className="c1"><span className="c3">User accounts &nbsp;</span></p>
            <p className="c1"><span className="c0">As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password.</span></p>
            <p className="c1"><span className="c0">If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address it accordingly.</span></p>
            <p className="c1"><span className="c0">We reserve all rights to terminate accounts, edit or remove content and in our sole discretion.</span></p>
            <p className="c1"><span className="c3">Applicable law</span></p>
            <p className="c1"><span className="c5">By visiting this website, you agree that the laws of Pennsylvania</span><span className="c4">,</span><span className="c0">&nbsp;without regard to principles of conflict of laws, will govern these terms and conditions and any dispute of any sort that might come between us and you, or its business partners and associates.</span></p>
            <p className="c1"><span className="c3">Disputes</span></p>
            <p className="c1"><span className="c0">Any dispute related in any way to your visit to this website shall be arbitrated by state or federal court in Pennsylvania and you consent to exclusive jurisdiction and venue of such courts.</span></p>
            <p className="c1"><span className="c3">Indemnification</span></p>
            <p className="c1"><span className="c5">You agree to indemnify </span><span className="c4">Pinpoint Technology Solutions LLC</span><span className="c0">&nbsp;and its affiliates and hold us harmless against legal claims and demands that may arise from your use or misuse of our services. We reserve the right to select our own legal counsel. </span></p>
            <p className="c1"><span className="c3">Limitation on liability</span></p>
            <p className="c1"><span className="c0">We are not liable for any damages that may occur to you as a result of your misuse of our website. We reserve the right to edit, modify, and change this Agreement any time. We shall let our users know of these changes through electronic mail. This Agreement is an understanding between the Company and the user, and this supersedes and replaces all prior agreements regarding the use of this service.</span></p>
            <p className="c1"><span className="c3">Payment terms</span></p>
            <p className="c1"><span className="c0">We use stripe on backend for payment processing. Users will be billed on the first day of every month based on previous month&rsquo;s usage.</span></p>
            <p className="c1"><span className="c0">The charges in regards to the services to be provided are on a per transaction basis. The liability therefore is limited to the cost of each transaction.</span></p>
            <p className="c1"><span className="c0">A user shall be entitled to a refund on a faulty transaction only and no other compensation shall be made afterwards.</span></p>
            <p className="c1"><span className="c0">For the purposes of this agreement, a &ldquo;faulty transaction&rdquo; shall include;</span></p>
            <ol className="c9 lst-kix_91uemewk7k2f-0 start" start="1">
                <li className="c2 li-bullet-0"><span className="c0">An error on our side. If an error committed by us has occurred therefore making the transaction faulty, the user will not be charged.</span></li>
            </ol>
            <p className="c1"><span className="c0">A transaction will not be termed as faulty if the mistake is not ours. A transaction will not be faulty if;</span></p>
            <ol className="c9 lst-kix_8267syw2sud7-0 start" start="1">
                <li className="c2 li-bullet-0"><span className="c0">User gives the wrong address</span></li>
                <li className="c2 li-bullet-0"><span className="c0">User puts in ambiguity in information leading to non-accurate results</span></li>
                <li className="c2 li-bullet-0"><span className="c0">User gives misleading information</span></li>
            </ol>
            <p className="c1 c8"><span className="c0">User shall be charged if they inconvenience a transaction whatsoever.</span></p>
            <p className="c1 c8"><span className="c5">In the event where we are liable for a transaction which is faulty, a user has 30 calendar days to make a claim. Any claim that comes after the 30 days lapse will not be catered for.</span></p>
            <p className="c13"><span className="c12"></span></p>
        </div>
    )  
}

export default TermsOfUse;
