import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { TransactionalAPI } from "../../api/axiosBase";

import "../../styles/dashboard/dashboard.scss";

const UsageGraph = () => {
    const PAGE_SIZE = 4;

    const [totalsPageNumber, setTotalsPageNumber] = useState(0);
    const [totalsPageCount, setTotalsPageCount] = useState(0);


    const [groupPageNumber, setGroupPageNumber] = useState({});
    const [groupPageCount, setGroupPageCount] = useState({});



    const [usageData, setUsageData] = useState(null);

    useEffect(() => {
        TransactionalAPI
            .get(`getUsageData`)
            .then((response) => {
                console.log("usage data", response)
                setUsageData(response.data.usage);

                setTotalsPageNumber(0);
                setTotalsPageCount(Math.ceil(response.data.usage.totals.length / PAGE_SIZE));

                let { gpn, gpc} = response.data.usage.groups.reduce((accum, g) => {
                    accum.gpn[g.group] = 0;
                    accum.gpc[g.group] = Math.ceil(g.values.length / PAGE_SIZE);

                    return accum;
                }, { gpn: {}, gpc: {} })

                setGroupPageNumber(gpn);
                setGroupPageCount(gpc);
            });
    }, []);

    //useEffect(() => {
    //    console.log("paging", { groupPageNumber, groupPageCount });
    //}, [groupPageNumber, groupPageCount])

    return (
        <div id="UsageReport" className="col-3 pt-5">
            <h2>Individual Usage Report</h2>
            <div className="card py-2 mb-3">
                <h3 className="text-center">Totals</h3>
                <div className="row">
                    <div className="col-6"></div>
                    <div className="col-6 font-weight-bold">Lookups</div>
                </div>
                {!!usageData && !!usageData.totals &&
                    usageData.totals.slice(totalsPageNumber * PAGE_SIZE, totalsPageNumber * PAGE_SIZE + PAGE_SIZE).map(({ time_stamp, num }, index) =>
                        <div className="row report-item" key={index}>
                            <div className="col-6">
                                <span className="date">
                                    <Moment format="DD MMM YYYY">{time_stamp}</Moment>
                                </span>
                            </div>
                            <div className="col-6">{num}</div>
                        </div>
                    )
                }
                <div className="btn-group">{[...Array(totalsPageCount).keys()].map(i => (<button key={i} className="btn btn-xs" onClick={() => setTotalsPageNumber(i)}>{i + 1}</button>))}</div>
            </div>

            {!!usageData && !!usageData.groups &&
                usageData.groups.map(({ group, values }, groupIndex) => (
                    <div className="card py-2 my-3" key={groupIndex}>
                        <h3 className="text-center">{group.split(/(?=[A-Z])/).join(' ')}</h3>
                        <div className="row">
                            <div className="col-6"></div>
                            <div className="col-6 font-weight-bold">Lookups</div>
                        </div>
                        {!!values &&
                            values.slice(groupPageNumber[group] * PAGE_SIZE, groupPageNumber[group] * PAGE_SIZE + PAGE_SIZE).map(({ time_stamp, num }, index) => (
                                <div className="row report-item" key={index}>
                                    <div className="col-6">
                                        <span className="date">
                                            <Moment format="DD MMM YYYY">{time_stamp}</Moment>
                                        </span>
                                    </div>
                                    <div className="col-6">{num}</div>
                                </div>
                            ))
                        }

                        <div className="btn-group">{[...Array(groupPageCount[group]).keys()].map(i => (<button key={i} className="btn btn-xs" onClick={() => {
                            let newPages = { ...groupPageNumber };
                            newPages[group] = i;
                            setGroupPageNumber(newPages)
                        }}>{i + 1}</button>))}</div>
                    </div>

                ))
            }

        </div>
    );
};

export default UsageGraph;
