import React from "react";
import {CardElement} from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#39517a",
      fontSize: "16px",
      "::placeholder":{
        color: "#f2555c"
      }
    }
  },
  invalid: "#f29f05",
  iconColor: "#f29f05"
};
const CardInput = () => {
  return (
    <label>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </label>
  )
}

export default CardInput;
