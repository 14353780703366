export const dataProccessor = (data, isRatesChecked, isWageChecked) => {
    const capitalizeFirstLetter = (string) =>  {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const buildCollectorColumns = (collectorDataArray, labelName, shortname) => {
        return (!!collectorDataArray.some(d => !!d)) ?
            [
                { label: `PA Local ${labelName} Tax collector name`, value: `paLocal_${shortname}_name` },
                { label: `PA Local ${labelName} address line 1`, value: `paLocal_${shortname}_addressLine1` },
                { label: `PA Local ${labelName} address line 2`, value: `paLocal_${shortname}_addressLine2` },
                { label: `PA Local ${labelName} address line 3`, value: `paLocal_${shortname}_addressLine3` },
                { label: `PA Local ${labelName} city name`, value: `paLocal_${shortname}_city` },
                { label: `PA Local ${labelName} county name`, value: `paLocal_${shortname}_county` },
                { label: `PA Local ${labelName} state abbreviation`, value: `paLocal_${shortname}_state` },
                { label: `PA Local ${labelName} 5 digit zip code`, value: `paLocal_${shortname}_zipCode` },
                { label: `PA Local ${labelName} 4 digit zip code extension`, value: `paLocal_${shortname}_zip4` },
                { label: `PA Local ${labelName} Phone number`, value: `paLocal_${shortname}_phone` },
                { label: `PA Local ${labelName} Toll free phone number`, value: `paLocal_${shortname}_tollFree` },
                { label: `PA Local ${labelName} Fax number`, value: `paLocal_${shortname}_fax` },
                { label: `PA Local ${labelName} email address`, value: `paLocal_${shortname}_email` },
                { label: `PA Local ${labelName} Phone extension`, value: `paLocal_${shortname}_extension` },
                { label: `PA Local ${labelName} Collector URL`, value: `paLocal_${shortname}_url` },
            ]
            : [];
    }

    const buildCollectorData = (collectorData, shortname) => {
        if (!!collectorData) {
            let obj = {};

            obj[`paLocal_${shortname}_name`] = collectorData.name;
            obj[`paLocal_${shortname}_addressLine1`] = collectorData.addressLine1;
            obj[`paLocal_${shortname}_addressLine2`] = collectorData.addressLine2;
            obj[`paLocal_${shortname}_addressLine3`] = collectorData.addressLine3;
            obj[`paLocal_${shortname}_city`] = collectorData.city;
            obj[`paLocal_${shortname}_county`] = collectorData.county;
            obj[`paLocal_${shortname}_state`] = collectorData.state;
            obj[`paLocal_${shortname}_zipCode`] = collectorData.zipCode;
            obj[`paLocal_${shortname}_zip4`] = collectorData.zip4;
            obj[`paLocal_${shortname}_phone`] = collectorData.phone;
            obj[`paLocal_${shortname}_tollFree`] = collectorData.tollFree;
            obj[`paLocal_${shortname}_fax`] = collectorData.fax;
            obj[`paLocal_${shortname}_email`] = collectorData.email;
            obj[`paLocal_${shortname}_extension`] = collectorData.extension;
            obj[`paLocal_${shortname}_url`] = collectorData.url;

            return obj;
        }
    }
    

    // Populate Columns 

    // Common fields
    let columns = [
        { label: "Address", value: "address" },
        { label: "Address Line 1", value: "street1" },
        { label: "City", value: "city" },
        { label: "County", value: "county" },
        { label: "State", value: "state" },
        { label: "5 digit zip code", value: "zipCode" },
        { label: "4 digit zip code", value: "zip4" },
        { label: "Coordinates Latititude", value: "lat" },
        { label: "Coordinates Longitude", value: "lng" }
    ];

    // School districts
    if (data.some((d) => !!d.schoolDistricts && !!d.schoolDistricts.unified)) {
        columns = [...columns, 
            { label: "School District Name", value: "schoolDistricts_unified_name" },
            { label: "School Districts Lea Code", value: "schoolDistricts_unified_lea_code" },
            { label: "School Districts Grade Low", value: "schoolDistricts_unified_grade_low" },
            { label: "School Districts Grade High", value: "schoolDistricts_unified_grade_high" }
        ];
    }

    //  MinWage
    //for (let scope of new Set(data.filter(({ minimumWage }) => !!minimumWage).map(({ minimumWage }) => Object.keys(minimumWage)).flat())) {
    for (let scope of ["federal", "state", "county", "city"]) {
        columns = [...columns,
            { label: `${capitalizeFirstLetter(scope)} Minimum Wage Name`, value: `minimumWage_${scope}_state` },
        ];

        if(scope === "federal") {
            columns = [...columns,
                { label: `${capitalizeFirstLetter(scope)} Minimum Wage`, value: `minimumWage_${scope}_wage1` },
                { label: `${capitalizeFirstLetter(scope)} Minimum Tipped Wage`, value: `minimumWage_${scope}_tippedWage1` },
                { label: `${capitalizeFirstLetter(scope)} Minimum Wage Effective Date`, value: `minimumWage_${scope}_effectiveDate` },
            ];
        }
        else {
            if(scope !== "state") {
                columns = [...columns,
                    { label: `${capitalizeFirstLetter(scope)} Locality`, value: `minimumWage_${scope}_locality` },    
                ];
            }

            columns = [...columns,
                { label: `${capitalizeFirstLetter(scope)} Wage 1`, value: `minimumWage_${scope}_wage1` },
                { label: `${capitalizeFirstLetter(scope)} Wage 1 with Benefits`, value: `minimumWage_${scope}_wage1WithBenefits` },
                { label: `${capitalizeFirstLetter(scope)} Tipped Wage 1`, value: `minimumWage_${scope}_tippedWage1` },
                { label: `${capitalizeFirstLetter(scope)} Employees Less Than or Equal To`, value: `minimumWage_${scope}_employeesLessThanOrEqualTo` },
                { label: `${capitalizeFirstLetter(scope)} Revenue Less than`, value: `minimumWage_${scope}_revenueLessThan` },

                { label: `${capitalizeFirstLetter(scope)} Wage 2`, value: `minimumWage_${scope}_wage2` },
                { label: `${capitalizeFirstLetter(scope)} Wage 2 with Benefits`, value: `minimumWage_${scope}_wage2WithBenefits` },
                { label: `${capitalizeFirstLetter(scope)} Tipped Wage 2`, value: `minimumWage_${scope}_tippedWage2` },

                { label: `${capitalizeFirstLetter(scope)} Effective Date`, value: `minimumWage_${scope}_effectiveDate` },
                { label: `${capitalizeFirstLetter(scope)} Note`, value: `minimumWage_${scope}_note` },
            ];
        }
    }

    // Rates
    let ratesFieldCount = 0;

    if (data.some(d => !!d.localInfo)) {
        // General Rates
        ratesFieldCount = Math.max(...data.map(({ localInfo }) => !!localInfo && !!localInfo.rates ? localInfo.rates.length : 0));

        for (let i = 1; i <= ratesFieldCount; i++) {
            columns = [...columns,
            { label: `Rate ${i} State Abbreviation`, value: `rate_${i}_state` },
            { label: `Rate ${i} County Name`, value: `rate_${i}_county` },
            { label: `Rate ${i} Jurisdiction Name`, value: `rate_${i}_jurisdiction` },
            { label: `Rate ${i} Zip Code`, value: `rate_${i}_zipCode` },
            { label: `Rate ${i} Tax Name`, value: `rate_${i}_taxname` },
            { label: `Rate ${i} Level`, value: `rate_${i}_rateLevel` },
            { label: `Rate ${i} EE/ER`, value: `rate_${i}_eeer` },
            { label: `Rate ${i} Resident Rate`, value: `rate_${i}_residentRate` },
            { label: `Rate ${i} Non ResidentRate Rate`, value: `rate_${i}_nonResidentRate` },
            { label: `Rate ${i} Default Rate`, value: `rate_${i}_defaultRate` },
            { label: `Rate ${i} Maximum Tax Amount`, value: `rate_${i}_maxtax` },
            { label: `Rate ${i} Flat Tax Amount`, value: `rate_${i}_amount` },
            { label: `Rate ${i} Mental health tax`, value: `rate_${i}_mentalHealthRate` },
            { label: `Rate ${i} School district tax`, value: `rate_${i}_schoolDistrictRate` },
            { label: `Rate ${i} Individual exemption limit`, value: `rate_${i}_taxExemptLimit` },
            { label: `Rate ${i} Notes associated with tax`, value: `rate_${i}_note` },
            ];
        }

        // OH Local
        if (data.some(d => !!d.localInfo && !!d.localInfo.ohLocal)) {
            columns = [...columns,
                { label: "OH Local Municipality Municipality name", value: "ohLocal_municipality_name" },
                { label: "OH Local Municipality Municipality income tax rate", value: "ohLocal_municipality_rate" },
                { label: "OH Local Municipality Effective date", value: "ohLocal_municipality_effectiveDate" },
                { label: "OH Local Municipality Effective end date", value: "ohLocal_municipality_effEndDate" },

                { label: "OH Local School District name", value: "ohLocal_schoolDistrict_name" },
                { label: "OH Local School District Department of Education IRN number", value: "ohLocal_schoolDistrict_odeNumber" },
                { label: "OH Local School District Taxation number", value: "ohLocal_schoolDistrict_schoolDistrictID" },
                { label: "OH Local School District income tax rate", value: "ohLocal_schoolDistrict_rate" },
                { label: "OH Local School District Effective date", value: "ohLocal_schoolDistrict_effectiveDate" },
                { label: "OH Local School District Effective end date", value: "ohLocal_schoolDistrict_effEndDate" },

                { label: "OH Local JEDDZ JEDD/JEDZ name", value: "ohLocal_jeddz_name" },
                { label: "OH Local JEDDZ JEDD/JEDZ Id", value: "ohLocal_jeddz_jeddzId" },
                { label: "OH Local JEDDZ JEDD/JEDZ income tax rate", value: "ohLocal_jeddz_rate" },
                { label: "OH Local JEDDZ EE/ER", value: "ohLocal_jeddz_eeer" },
                { label: "OH Local JEDDZ Effective date", value: "ohLocal_jeddz_effectiveDate" },
                { label: "OH Local JEDDZ Effective end date", value: "ohLocal_jeddz_effEndDate" },
            ];
        }


        // PA Local
        if (data.some(d => !!d.localInfo && !!d.localInfo.paLocal && !!d.localInfo.paLocal.taxCollectionDistrict)) {
            columns = [...columns,
                { label: "PA Local Name of the tax collection district", value: "paLocal_taxCollectionDistrictName" },
                { label: "PA Local county name", value: "paLocal_county" },
                { label: "PA Local municipality name", value: "paLocal_municipality" },
                { label: "PA Local unique municipality ID code", value: "paLocal_municipalityID" },
                { label: "PA Local School district name", value: "paLocal_schoolDistrict" },
                { label: "PA Local unique school district ID code", value: "paLocal_schoolDistrictID" },
                { label: "PA Local Municipal resident earned income tax rate", value: "paLocal_municipalResidentEITRate" },
                { label: "PA Local Municipal resident earned income tax rate effective date", value: "paLocal_municipalResidentEITRateEffDate" },
                { label: "PA Local Municipal nonresident earned income tax rate", value: "paLocal_municipalNonResidentEITRate" },
                { label: "PA Local Municipal nonresident earned income tax rate effective date", value: "paLocal_municipalNonResidentEITRateEffDate" },
                { label: "PA Local Municipal local services tax amount(dollars)", value: "paLocal_municipalLST" },
                { label: "PA Local Municipal local services tax effective date", value: "paLocal_municipalLSTEffDate" },
                { label: "PA Local School district earned income tax rate", value: "paLocal_schoolDistrictEITRate" },
                { label: "PA Local School district earned income tax effective date", value: "paLocal_schoolDistrictEITRateEffDate" },
                { label: "PA Local School District personal income tax rate", value: "paLocal_schoolDistrictPITRate" },
                { label: "PA Local School District local services tax rate", value: "paLocal_schoolDistrictLST" },
                { label: "PA Local School District local services tax effective date", value: "paLocal_schoolDistrictLSTEffDate" },
                { label: "PA Local Municipal earned income tax low income", value: "paLocal_municipalEITLIE" },
                { label: "PA Local School district earned income tax low income", value: "paLocal_schooDistrictEITLIE" },
                ...buildCollectorColumns(data.map(d => !!d.localInfo && !!d.localInfo.paLocal && !!d.localInfo.paLocal.taxCollectionDistrict ? d.localInfo.paLocal.taxCollectionDistrict.eitCollector : null), "Earned income tax collector", "eitCollector"),
                ...buildCollectorColumns(data.map(d => !!d.localInfo && !!d.localInfo.paLocal && !!d.localInfo.paLocal.taxCollectionDistrict ? d.localInfo.paLocal.taxCollectionDistrict.municipalLSTCollector : null), "Municipal local services tax collector", "municipalLSTCollector"),
                ...buildCollectorColumns(data.map(d => !!d.localInfo && !!d.localInfo.paLocal && !!d.localInfo.paLocal.taxCollectionDistrict ? d.localInfo.paLocal.taxCollectionDistrict.schoolDistrictLSTCollector : null), "School District local services tax collector", "schoolDistrictLSTCollector"),
            ];
        }
    }


    // Populate values
    let values = data.map(({ address, coordinates, schoolDistricts, minimumWage, localInfo }) => {
        // Common fields
        let obj = {
            address: address.address,
            street1: address.street1,
            city: address.city,
            county: address.county,
            state: address.state,
            zipCode: address.zipCode,
            zip4: address.zip4,
            lat: coordinates.lat,
            lng: coordinates.lng,
        };

        // School districts
        if (!!schoolDistricts && !!schoolDistricts.unified) {
            obj.schoolDistricts_unified_name = schoolDistricts.unified.name;
            obj.schoolDistricts_unified_lea_code = schoolDistricts.unified.lea_code;
            obj.schoolDistricts_unified_grade_low = schoolDistricts.unified.grade_low;
            obj.schoolDistricts_unified_grade_high = schoolDistricts.unified.grade_high;
        }

        //  MinWage
        for (let scope in minimumWage) {
            
            obj[`minimumWage_${scope}_state`] = minimumWage[scope].state;
            obj[`minimumWage_${scope}_wage1`] = minimumWage[scope].wage1;
            obj[`minimumWage_${scope}_tippedWage1`] = minimumWage[scope].tippedWage1;
            obj[`minimumWage_${scope}_effectiveDate`] = minimumWage[scope].effectiveDate;

            if(scope !== "federal") {
                if(scope !== "state") {
                    obj[`minimumWage_${scope}_locality`] = minimumWage[scope].locality;
                }

                obj[`minimumWage_${scope}_wage1WithBenefits`] = minimumWage[scope].wage1WithBenefits;
                obj[`minimumWage_${scope}_employeesLessThanOrEqualTo`] = minimumWage[scope].employeesLessThanOrEqualTo;
                obj[`minimumWage_${scope}_revenueLessThan`] = minimumWage[scope].revenueLessThan;

                obj[`minimumWage_${scope}_wage2`] = minimumWage[scope].wage2;
                obj[`minimumWage_${scope}_tippedWage2`] = minimumWage[scope].tippedWage2;
                obj[`minimumWage_${scope}_wage2WithBenefits`] = minimumWage[scope].wage2WithBenefits;
                
                obj[`minimumWage_${scope}_note`] = minimumWage[scope].note;
            }
        }

        // Rates
        if (!!localInfo) {
            for (let i = 0; i < (!!localInfo.rates ? localInfo.rates.length : 0); i++) {
                obj[`rate_${i + 1}_state`] = localInfo.rates[i].state;
                obj[`rate_${i + 1}_county`] = localInfo.rates[i].county;
                obj[`rate_${i + 1}_jurisdiction`] = localInfo.rates[i].jurisdiction;
                obj[`rate_${i + 1}_zipCode`] = localInfo.rates[i].zipCode;
                obj[`rate_${i + 1}_taxname`] = localInfo.rates[i].taxname;
                obj[`rate_${i + 1}_rateLevel`] = localInfo.rates[i].rateLevel;
                obj[`rate_${i + 1}_eeer`] = localInfo.rates[i].eeer;
                obj[`rate_${i + 1}_residentRate`] = localInfo.rates[i].residentRate;
                obj[`rate_${i + 1}_nonResidentRate`] = localInfo.rates[i].nonResidentRate;
                obj[`rate_${i + 1}_defaultRate`] = localInfo.rates[i].defaultRate;
                obj[`rate_${i + 1}_maxtax`] = localInfo.rates[i].maxtax;
                obj[`rate_${i + 1}_amount`] = localInfo.rates[i].amount;
                obj[`rate_${i + 1}_mentalHealthRate`] = localInfo.rates[i].mentalHealthRate;
                obj[`rate_${i + 1}_schoolDistrictRate`] = localInfo.rates[i].schoolDistrictRate;
                obj[`rate_${i + 1}_taxExemptLimit`] = localInfo.rates[i].taxExemptLimit;
                obj[`rate_${i + 1}_note`] = localInfo.rates[i].note;
            }


            // OH Local
            if (!!localInfo.ohLocal) {
                if (!!localInfo.ohLocal.municipality) {
                    obj["ohLocal_municipality_name"] = localInfo.ohLocal.municipality.name;
                    obj["ohLocal_municipality_rate"] = localInfo.ohLocal.municipality.rate;
                    obj["ohLocal_municipality_effectiveDate"] = localInfo.ohLocal.municipality.effectiveDate;
                    obj["ohLocal_municipality_effEndDate"] = localInfo.ohLocal.municipality.effEndDate;
                }
                if (!!localInfo.ohLocal.schoolDistrict) {
                    obj["ohLocal_schoolDistrict_name"] = localInfo.ohLocal.schoolDistrict.name;
                    obj["ohLocal_schoolDistrict_odeNumber"] = localInfo.ohLocal.schoolDistrict.odeNumber;
                    obj["ohLocal_schoolDistrict_schoolDistrictID"] = localInfo.ohLocal.schoolDistrict.schoolDistrictID;
                    obj["ohLocal_schoolDistrict_rate"] = localInfo.ohLocal.schoolDistrict.rate;
                    obj["ohLocal_schoolDistrict_effectiveDate"] = localInfo.ohLocal.schoolDistrict.effectiveDate;
                    obj["ohLocal_schoolDistrict_effEndDate"] = localInfo.ohLocal.schoolDistrict.effEndDate;
                }
                if (!!localInfo.ohLocal.jeddz) {
                    obj["ohLocal_jeddz_name"] = localInfo.ohLocal.jeddz.name;
                    obj["ohLocal_jeddz_jeddzId"] = localInfo.ohLocal.jeddz.jeddzId;
                    obj["ohLocal_jeddz_rate"] = localInfo.ohLocal.jeddz.rate;
                    obj["ohLocal_jeddz_eeer"] = localInfo.ohLocal.jeddz.eeer;
                    obj["ohLocal_jeddz_effectiveDate"] = localInfo.ohLocal.jeddz.effectiveDate;
                    obj["ohLocal_jeddz_effEndDate"] = localInfo.ohLocal.jeddz.effEndDate;
                }
            }

            if (!!localInfo.paLocal && !!localInfo.paLocal.taxCollectionDistrict) {
                let paLocal = localInfo.paLocal.taxCollectionDistrict;
                obj["paLocal_taxCollectionDistrictName"] = paLocal.taxCollectionDistrictName;
                obj["paLocal_county"] = paLocal.county;
                obj["paLocal_municipality"] = paLocal.municipality;
                obj["paLocal_municipalityID"] = paLocal.municipalityID;
                obj["paLocal_schoolDistrict"] = paLocal.schoolDistrict;
                obj["paLocal_schoolDistrictID"] = paLocal.schoolDistrictID; 
                obj["paLocal_municipalResidentEITRate"] = paLocal.municipalResidentEITRate;
                obj["paLocal_municipalResidentEITRateEffDate"] = paLocal.municipalResidentEITRateEffDate;
                obj["paLocal_municipalNonResidentEITRate"] = paLocal.municipalNonResidentEITRate;
                obj["paLocal_municipalNonResidentEITRateEffDate"] = paLocal.municipalNonResidentEITRateEffDate;
                obj["paLocal_municipalLST"] = paLocal.municipalLST;
                obj["paLocal_municipalLSTEffDate"] = paLocal.municipalLSTEffDate;
                obj["paLocal_schoolDistrictEITRate"] = paLocal.schoolDistrictEITRate;
                obj["paLocal_schoolDistrictEITRateEffDate"] = paLocal.schoolDistrictEITRateEffDate;
                obj["paLocal_schoolDistrictPITRate"] = paLocal.schoolDistrictPITRate;
                obj["paLocal_schoolDistrictLST"] = paLocal.schoolDistrictLST;
                obj["paLocal_schoolDistrictLSTEffDate"] = paLocal.schoolDistrictLSTEffDate;
                obj["paLocal_municipalEITLIE"] = paLocal.municipalEITLIE;
                obj["paLocal_schooDistrictEITLIE"] = paLocal.schooDistrictEITLIE;

                obj = { ...obj, ...buildCollectorData(paLocal.eitCollector, "eitCollector") }
                obj = { ...obj, ...buildCollectorData(paLocal.municipalLSTCollector, "municipalLSTCollector") }
                obj = { ...obj, ...buildCollectorData(paLocal.schoolDistrictLSTCollector, "schoolDistrictLSTCollector") }

            }
        }


        return obj;
    });

    return { columns, values };
};
