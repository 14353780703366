import React from "react";
import { OutTable } from "react-excel-renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "../../styles/spreadsheet-processor/preview.scss";

const Preview = ({ cols, rows }) => {
  return (
    <div id="PreviewContainer">
      {cols ? (
        <>
          <h2>
            Preview &nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon icon={faChevronDown} />
          </h2>
          <div className="preview-content">
            <OutTable
              data={rows}
              columns={cols}
              tableClassName="ExcelTable2007"
              tableHeaderRowClass="heading"
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Preview;
