import React, { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "../../styles/dashboard/progressbar-overrides.css";
import "../../styles/dashboard/dashboard.scss";
import { TransactionalAPI } from "../../api/axiosBase";

const Balance = () => {

    const [totalDue, setTotalDue] = useState(0);
    const [transactionsCount, setTransactionsCount] = useState(0);
    const [lastCharge, setLastCharge] = useState(0);

    useEffect(() => {
        TransactionalAPI
            .get(`getLastCharge`)
            .then((response) => {
                setLastCharge(response.data.last_charge);
            });

        TransactionalAPI
            .get(`getDue`)
            .then((response) => {
                setTotalDue(response.data.due);
            });

        TransactionalAPI
            .get(`transactions`)
            .then((response) => {
                setTransactionsCount(response.data.count);
                console.log(
                    "transactions count",
                    response
                );
            });
    }, []);

    return (
        <div id="Balance">
            <h3>Balance</h3>
            <div className="row">
                <div className="col-4">
                    <div className="card">
                        <div className="row">
                            <div className="col-2">
                                <CircularProgressbar
                                    strokeWidth="5"
                                    className="circular-progressbar"
                                    value={transactionsCount}
                                    text={""}
                                />
                            </div>
                            <div className="col-10 pt-3">
                                <p className="custom-font">Total transactions</p>
                                <h3 className="font-weight-bold">
                                    {transactionsCount}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card">
                        <div className="row">
                            <div className="col-2">
                                <CircularProgressbar
                                    strokeWidth="5"
                                    className="circular-progressbar"
                                    value={lastCharge}
                                    text={""}
                                />
                            </div>
                            <div className="col-10 pt-3">
                                <p className="custom-font">Last charge</p>
                                <h3 className="font-weight-bold">
                                    <CurrencyFormat
                                        value={lastCharge.toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                    />
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card">
                        <div className="row">
                            <div className="col-2">
                                <CircularProgressbar
                                    strokeWidth="5"
                                    className="circular-progressbar"
                                    value={totalDue}
                                    text={""}
                                />
                            </div>
                            <div className="col-10 pt-3">
                                <p className="custom-font">Total charges</p>
                                <h3 className="font-weight-bold">
                                    <CurrencyFormat
                                        value={totalDue.toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                    />
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Balance;
