import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from "react-toastify";
import { Form, Button } from "react-bootstrap";
import { Alert } from 'react-bootstrap';
import Loader from "react-loader-spinner";
import { Link } from 'react-router-dom';

import "../styles/user-registration/user-login.scss";
import { TransactionalAPI } from '../api/axiosBase';

const ChangePasswordPage = () => {
    const {email, token} = useParams();
    const [userId, setUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [passwordReg, setPasswordReg] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    let history = useHistory();

    useEffect(() => {
        setIsLoading(true);

        TransactionalAPI
            .post(`prepare-change-password`, {
                email: email,
                token
            })
            .then((response) => {
                if (response.data.ok === true) {
                    setUserId(response.data.user_id);
                }
                setIsLoading(false);
            });

    }, [email, token])

    const registeredToast = () =>
        toast.success("Password change succeded, log in to continue, redirecting to login in 5 seconds", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        
    const registeredToastWrong = (message) =>
    toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    

    const changePassword = () => {
        if (passwordReg === undefined || passwordReg === "") {
          registeredToastWrong("Provide a valid password");
        } else if (passwordReg !== confirmPassword) {
          registeredToastWrong("Password doesn't match");
        }
        else {
          TransactionalAPI
            .post(`change-password`, {
              user_id: userId,
              token,
              password: passwordReg
            })
            .then((response) => {
              if (response.data.ok === true) {
                registeredToast();
                setTimeout(() => history.push('/register'), 5000);
              } else {
                registeredToastWrong("Error changing password");
              }
            });
        }
      };


    if(!email || !token) {
        return <Alert variant='danger'>Missing email or token</Alert>    
    }

    return (
        <div>
            {!isLoading ? (
                !!userId  ? 
                    <div id="Login">
                        <div className="login-container">
                            <h4>Change your password</h4>
                            <Form>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        onChange={(e) => setPasswordReg(e.target.value)}
                                        type="password"
                                        placeholder="Password"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBasicConfirmPassword">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        type="password"
                                        placeholder="Password"
                                    />
                                </Form.Group>
                                <div className="text-center pt-5">
                                    <Button
                                        className="sign-in-btn"
                                        onClick={changePassword}
                                        variant="primary"              
                                    >
                                        Change Password
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                    :
                    <Alert variant='danger'>
                        Email or Token invalid or expired, please request a new one in <Link to='/recover-password'>here</Link>
                    </Alert>
            ) : (
                <div className="text-center" style={{paddingTop:"25%"}}>
                <Loader
                    type="ThreeDots"
                    color="#39517a"
                    height={100}
                    width={100}
                    timeout={3000} //3 secs
                />
                </div>
            )}
        </div>
    )
}
export default ChangePasswordPage;