import React from "react";
import JSONPretty from "react-json-pretty";
import { exampleRequest } from "../../assets/exampleMinWageRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import "../../styles/home-page/custom-theme.css";

const MinimumWageExample = () => {
  return (
    <div className="row mx-auto">
      <div className="col-6 p-5">
        <div className="align-middle p-ex">
          <h3 className="example-title">Minimum Wage</h3>
          <p className="example-p">
            Quickly and accurately identify minimum wage rates at the federal,
            state and local levels. Simply input the employee's work address.
            PayRate will geocode the address, identify any relevant
            jurisdictions containing minimum hourly wages for regular employees
            and tipped workers for both large and small companies.
          </p>
        </div>
      </div>
      <div className="col-6 p-5">
        <div className="top-background shadow-sm">
          <FontAwesomeIcon
            className="pl-1"
            style={{ color: "#f2555c" }}
            icon={faCircle}
          />
          <FontAwesomeIcon
            className="pl-1"
            style={{ color: "#efc778" }}
            icon={faCircle}
          />
          <FontAwesomeIcon
            className="pl-1"
            style={{ color: "#b5e08d" }}
            icon={faCircle}
          />
        </div>
        <div className="example-json-container shadow-sm">
          <JSONPretty id="json-pretty" data={exampleRequest} />
        </div>
      </div>
    </div>
  );
};

export default MinimumWageExample;
