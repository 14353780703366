import React from "react";
import ReactExport from "react-data-export";
import { dataProccessor } from "./dataProccessor";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Processor = ({ data, isRatesChecked, isWageChecked, filename }) => {
    let dataProccessed = dataProccessor(data, isRatesChecked, isWageChecked);
    console.log("processed data and columns", { data, dataProccessed });

    return (
        <ExcelFile filename={!!filename ? filename : "Download"} element={ <span><button>Download Processed File</button></span> }>
            <ExcelSheet data={dataProccessed.values} name="Result">
                {
                    dataProccessed.columns.map((c, i) => <ExcelColumn label={c.label} value={c.value} key={i} />)
                }
            </ExcelSheet>
        </ExcelFile>
    );
};

export default Processor;
