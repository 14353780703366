import React from "react";
import Presentation from "../components/home-page/Presentation";
import MapImage from "../components/home-page/MapImage";
import Services from "../components/home-page/Services";
import ResultExample from "../components/home-page/ResultExample";
import MinimumWageExample from "../components/home-page/MinimunWageExample";
import LiveDemoContainer from "../containers/LiveDemoContainer";
import "../styles/home-page/home-page.css";

class HomePage extends React.Component {
  render() {
    return (
      <>
        <div className="row mx-auto home-page-container">
          <div className="col-6">
            <Presentation />
          </div>
          <div className="col-6">
            <MapImage />
          </div>
        </div>
        <Services />
        <ResultExample />
        <MinimumWageExample />
        <LiveDemoContainer />
      </>
    );
  }
}

export default HomePage;
